import styles from './DatePickerComponent.module.css'

import { generateDateList, compareDate } from '../../../utils/UtilitaryFunctions';

import highlight from '../../../graphics/conference add/highlight-data.svg'
import { useContext, useEffect, useRef, useState } from 'react';

import { GlobalContext } from '../../../contexts/globalContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';


const DatePickerComponent = ({selectedDate, setSelectedDate}) => {
    const firstScrollDays = useRef()

    const currentDate = new Date();

    const [topPosition, setTopPosition] = useState(0);

    const {appWidth, appHeight} = useContext(GlobalContext)
    const {calendarRef, dataChanged, dayConferences} = useContext(AddConferenceContext)

    const dayOffset = parseInt(appWidth / 148.8 / 2) // 148.8 este latimea unei casute de data
    const margin = appWidth / 2 % 148.8 - 148.8 / 2 

    const dateList = generateDateList(currentDate, dayOffset);

    const oyDivider =  window.innerHeight/ appHeight;

    useEffect(() => {
        firstScrollDays.current = true
        calendarRef.current.style.marginLeft = `${margin}px`
    }, [])


    const handleScrollDay = () => {
        if(firstScrollDays.current !== true){
            dataChanged.current = true
        }

        if (calendarRef.current) {
            const calendarDays = calendarRef.current.children;

            for (let i = 0; i < calendarDays.length; i++) {
                const rect = calendarDays[i].getBoundingClientRect();

                if (rect.left >= -15) {
                    const date = new Date(currentDate);
                    date.setDate(currentDate.getDate() + i);

                    const d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                    const d2 = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());

                    if (d1.getTime() !== d2.getTime()){
                        setSelectedDate(date)
                    }
                        

                    break;
                }
            }
        }
        firstScrollDays.current = false
    };

    useEffect(() => {
        if (calendarRef.current) {
            const rect = calendarRef.current.getBoundingClientRect();
            setTopPosition(rect.y / oyDivider )
        }
    }, [dayConferences]);


    return (
        <>
            <img src={highlight} className={styles.selectedCalendarDay} style={{top: topPosition}}/>

            {selectedDate !== undefined && <div className={styles.calendar} ref={calendarRef} onScroll={handleScrollDay}>
                {dateList.map((item, index) => (
                    <div key={index} className={styles.calendarDay} style={{ color: compareDate( selectedDate, item.date) ? "#050020" : "" }}>
                        <div className={styles.calendarMonth}>{item.dayNumber === "01" ? item.month : ""}</div>
                        <div className={styles.calendarDayNumber}>{item.dayNumber}</div>
                        <div className={styles.calendarDayOfWeek}>{item.dayOfWeek}</div>
                    </div>
                ))}
            </div> }
        </>
    );

}

export default DatePickerComponent;