

import { useContext, useEffect, useRef } from 'react';
import { GlobalContext } from "../contexts/globalContext"
import io from "socket.io-client";
import { ApiGetNextOrActiveConference } from '../utils/RestServices';


const SocketManagerConference = () => {

    const { localSocketRef, conferenceSocketRef, currentParticipant, currentConference, setCurrentConference, setCurrentParticipant, deskData, setWaitingForJoining } = useContext(GlobalContext);

    useEffect(() => {
        if (currentParticipant) {
            localSocketRef.current = io.connect("http://localhost:3800", {
                secure: true,
                reconnect: true,
                rejectUnauthorized: true
            })

            localSocketRef.current.on('connect', function () {
                localSocketRef.current.emit("lift_panel");
            });


            conferenceSocketRef.current = io.connect(process.env.REACT_APP_CMS_URL, {
                secure: true,
                reconnect: true,
                rejectUnauthorized: true,
                query: { token: currentParticipant.token }
            })


            conferenceSocketRef.current.on('connect', function () {
                console.log("am cerat");
                conferenceSocketRef.current.emit("DESK_JOINED", deskData._id);
                conferenceSocketRef.current.emit("ADD_SOCKET_TO_CONFERENCE", currentConference._id);
            });

            conferenceSocketRef.current.on('DESK_PARTICIPANT_CHANGED', function () {
                var body = JSON.stringify({
                    deskId: deskData._id
                });

                ApiGetNextOrActiveConference(body, data => {
                    if (data.conference) {
                        setWaitingForJoining(true);
                        setCurrentParticipant(data.participant);
                        setCurrentConference(data.conference);
                    }
                });               
            });

            conferenceSocketRef.current.on('CONFERENCE_STOPED', function () {
                localSocketRef.current.emit("lower_panel");
                setCurrentConference(null);
                setCurrentParticipant(null);
            });


            return () => {
                conferenceSocketRef.current.disconnect()
                conferenceSocketRef.current = null
            }
        }
    }, [])

    return (
        <> </>
    );
}

export default SocketManagerConference;