import {  useEffect, useRef, useContext } from 'react';
import { GlobalContext } from "../../contexts/globalContext"

const AudioStreamComponent = (props) => {
    const { currentConference, conferenceSocketRef } = useContext(GlobalContext);
    const audioRef = useRef(null);

    useEffect(() => {
        audioRef.current.srcObject = props.audioStream.stream;       

        conferenceSocketRef.current.on('PLAY_VIDEOS', function () {
            audioRef.current.play();            
        });

        conferenceSocketRef.current.on('PAUSE_VIDEOS', function () {
            audioRef.current.pause();            
            audioRef.current.load();
        });
    }, [])

    return (
        <audio ref={audioRef} autoPlay={currentConference.type=="Presentation"?false:true}></audio>
    );
}

export default AudioStreamComponent;