import { useContext, useState, useEffect, useRef } from 'react';


import styles from './HeaderConferenceDetailsComponent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { GlobalContext } from '../../../contexts/globalContext';

import KeyboardComponent from '../../keyboard/KeyboardComponent';
import InputTextComponent from '../../keyboard/InputTextComponent';


const HeaderConferenceDetailsComponent = ({  }) => {

    const { editConference, setEditConference } = useContext(ProjectDetailsContext);

    const { appWidth, appHeight, socketRef, currentUser, selectedProject } = useContext(GlobalContext);

    const editNameRef = useRef()
    const keyboardRef = useRef()
    const [clickEditName, setClickEditName] = useState()
    const [isOnFocus, setIsOnFocus] = useState(false)


    function handleEditName() {
        if (editNameRef.current?.value !== editConference.name) {
            socketRef.current.emit("UPDATE_CONFERENCE", {projectId: selectedProject._id, conferenceId: editConference._id,  newData: { name: editNameRef.current?.value ? editNameRef.current?.value : "Default Title" } },
                (data) => {
                    let updatedName = { name: data.response.name ? data.response.name : editConference.name }
                    setEditConference(editConference => ({
                        ...editConference,
                        ...updatedName
                    }))
                }
            );
        }
        setClickEditName(false)

    }

    function handleCloseProject() {
        setEditConference()
    }


    return (
        <div style={{ flexDirection: "row", display: "flex" }}>
            {isOnFocus && <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60 - 240) / 2 - 180} y={appHeight * 0.68} width="2140px" height="610px" s="1" />}
            <div style={{display: 'flex', flexDirection: 'row'}} onClick={handleCloseProject}>
                <FontAwesomeIcon className={styles.goBack} icon={byPrefixAndName.fal["arrow-left"]}/>
                <div className={styles.goBackText} >Back</div>
            </div>

            {/* <div className={styles.titleCenterText} >{editConference.name}</div> */}

            {!clickEditName && <div className={styles.editName} style={{width: 2800, justifyContent: 'center'}}>
                <div className={styles.titleCenterText} style={{position: 'relative'}} onClick={() => setClickEditName(true)}>{editConference.name}</div>
                {(currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") &&
                    <FontAwesomeIcon className={styles.editIconClicked} style={{ top: 7 }} icon={byPrefixAndName.fal['pen']} onClick={() => setClickEditName(true)} />}
            </div>}

            {clickEditName && <div className={styles.editName}>
                <div className={styles.editBackground} />
                <InputTextComponent ref={editNameRef} styleType='EDT' keyboardRef={keyboardRef} value={editConference.name} onFocusFunction={() => handleEditName()} setIsOnFocus={setIsOnFocus} />
            </div>}

        </div>

    );

}

export default HeaderConferenceDetailsComponent;