import { useState, createContext } from "react"

export const ProjectDetailsContext = createContext([{}, () => {}])

export const ProjectDetailsProvider = ( props ) => {
    const [selectedParticipant, setSelectedParticipant] = useState()
    const [selectedFile, setSelectedFile] = useState()
    const [addGuest, setAddGuest] = useState()
    const [addTopic, setAddTopic] = useState()
    const [addFiles, setAddFiles] = useState()
    const [addParticipants, setAddParticipants] = useState()
    const [createConference, setCreateConference] = useState()
    const [createMeeting, setCreateMeeting] = useState()
    const [editConference, setEditConference] = useState()
    const [selectedConference, setSelectedConference] = useState()
    const [editTopic, setEditTopic] = useState()
    const [moreClicked, setMoreClicked] = useState(false)

    const [addMembers, setAddMembers] = useState()
    const [addPartners, setAddPartners] = useState()
    const [addVisistors, setAddVisitors] = useState()
    const [deleteClicked, setDeleteClicked] = useState(false)
    const [extended, setExtended] = useState(null)

    const [moreMenuTimer, setMoreMenuTimer] = useState(false)



    return (
        <ProjectDetailsContext.Provider value={{
            selectedParticipant, setSelectedParticipant,
            selectedFile, setSelectedFile,
            addGuest, setAddGuest,
            addTopic, setAddTopic,
            addFiles, setAddFiles,
            addParticipants, setAddParticipants,
            createConference, setCreateConference,
            createMeeting, setCreateMeeting,
            editConference, setEditConference,
            selectedConference, setSelectedConference,
            editTopic, setEditTopic,
            moreClicked, setMoreClicked,
            addMembers, setAddMembers,
            addPartners, setAddPartners,
            addVisistors, setAddVisitors,
            extended, setExtended,
            deleteClicked, setDeleteClicked,
            moreMenuTimer, setMoreMenuTimer
        }}>
            {props.children}
        </ProjectDetailsContext.Provider>
    )
}