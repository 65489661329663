import { useContext, useRef, useEffect, useState } from 'react';

import styles from './MoreMenuComponent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import { GlobalContext } from '../../contexts/globalContext';


const MoreMenuComponent = ({ moreClicked, setMoreClicked, setMoreMenuTimer }) => {

    const ref = useRef();

    const { isConf, clickEdit, clickClone, clickRemove, x, y } = moreClicked

    const { language, appWidth, appHeight } = useContext(GlobalContext);

    const oxDivider = window.innerWidth / appWidth;
    const oyDivider = window.innerHeight / appHeight;

    const [posX, setPosX] = useState(() => {
        let compWidth = 160

        let initialX = x / oxDivider + 124

        if(initialX + compWidth > appWidth){
            return appWidth - compWidth - 10
        }

        return initialX
    })
    const [posY, setPosY] = useState(() => {
        let compHeight = 160

        if(isConf){
            compHeight += 160
        }
        if(clickEdit){
            compHeight += 160
        }
        
        let initialY = y / oyDivider

        if(initialY + compHeight > appHeight){
            return appHeight - compHeight - 10
        }

        return initialY
    })

    useEffect(() => {
        ref.current.focus();
    }, []);



    return (
        <div ref={ref} className={styles.component} style={{ transform: `matrix(1, 0, 0, 1, ${posX}, ${posY})` }} tabIndex="0" 
        onBlur={() => {
            setMoreMenuTimer(true); 
            setMoreClicked({})
        }}>

            {clickEdit && <div className={styles.edit} onClick={clickEdit}>
                <FontAwesomeIcon icon={byPrefixAndName.fal['pen-to-square']} />
                <div className={styles.buttonText}>{language.more_menu.Edit.toUpperCase()}</div>
            </div>}
            {isConf && <div className={styles.edit} onClick={clickClone}>
                <FontAwesomeIcon icon={byPrefixAndName.fal['clone']} />
                <div className={styles.buttonText}>{language.more_menu.Clone.toUpperCase()}</div>
            </div>}
            <div className={styles.remove} onClick={clickRemove}>
                <FontAwesomeIcon icon={byPrefixAndName.fal['square-minus']} />
                <div className={styles.buttonText}>{language.more_menu.Remove.toUpperCase()}</div>
            </div>
        </div>

    );

}

export default MoreMenuComponent;