import { useRef, useContext, useState, useEffect } from 'react';
import styles from './AddVisitorsComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../../keyboard/InputTextComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/IconMembersAdd.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import OutsideGlowComponent from '../common/OutsideGlowComponent';


const AddVisitorsComponent = () => {

    const inputRefName = useRef()
    const inputRefEntitling = useRef()
    const keyboardRef = useRef()

    const [isOnFocusName, setIsOnFocusName] = useState(false)
    const [isOnFocusEntitling, setIsOnFocusEntitling] = useState(false)

    const { language, selectedProject, socketRef, appHeight, appWidth } = useContext(GlobalContext);
    const { setAddVisitors } = useContext(ProjectDetailsContext);

 

    function handleEmailAdd(){
        socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", { 
            projectId: selectedProject._id, 
            userData: {
                "name": inputRefName.current.value, 
                "email": output,
                "role": "VISITOR",
                "entitle": inputRefEntitling.current.value
            } 
        }, (callback) => {
            if(callback) console.log(callback)
            
        })
        setAddVisitors(false)
    }

    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');

    const transformString = (str) => {
        return str.toLowerCase().replace(/\s+/g, '.');
    };

    useEffect(() => {
        setOutput(transformString(input))
    },[input])

    
    return (
        <PopupBaseComponent>
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.6} width="2140px" height="610px" s="1" />
            <PopupBodyComponent style={{width: 2040, height: 840, top: "12%"}}>
                <ClosePageButtonComponent setClose={() => setAddVisitors(false)} />
                <LeftBodyComponent image={membersAdd} title="Add visitor to project" description="Create and add a new visitor."/>
                <RightBodyComponent>
                    <div className={styles.title}>New Visitor</div>

                    <div className={styles.subTitle}>Name</div>
                    {isOnFocusName && <OutsideGlowComponent style={{top: 327, left: 1031, width: 1001}}/>}
                    <InputTextComponent ref={inputRefName} onTextChanged={() => setInput(inputRefName.current.value)} keyboardRef={keyboardRef} value="" 
                    placeHolder="Enter name here" styleType='POPUP' width= "960px" fontSize="40px" setIsOnFocus={setIsOnFocusName}/>
                    {!isOnFocusName && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}

                    <div className={styles.username}>Username (automatically created): {output}</div>

                    <div className={styles.subTitle}>Entitling</div>
                    {isOnFocusEntitling && <OutsideGlowComponent style={{top: 570, left: 1031, width: 1001}}/>}
                    <InputTextComponent ref={inputRefEntitling} keyboardRef={keyboardRef} value="" 
                    placeHolder="Enter entitling here" styleType='POPUP' width= "960px" fontSize="40px" setIsOnFocus={setIsOnFocusEntitling}/>
                    {!isOnFocusEntitling && <FontAwesomeIcon className={styles.iconEdit} style={{marginTop: 523}} icon={byPrefixAndName.fal['pen']} />}

                    <FooterButtonsComponent 
                        lightText={"Add"} 
                        lightClick={inputRefName.current?.value === "" && !isOnFocusName ? () => {} : handleEmailAdd} 
                        isOpaque={inputRefName.current?.value === "" && !isOnFocusName } 
                        darkClick={setAddVisitors}
                    />
                </RightBodyComponent>
            </PopupBodyComponent>
            
        </PopupBaseComponent>
    );

}

export default AddVisitorsComponent;