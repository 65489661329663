import { useContext, useState, useEffect } from 'react';
import styles from './ConferenceScreenComponent.module.css'
import SocketManagerConference from '../../utils/SocketManagerConference';
import WaitingForJoiningScreen from './WaitingForJoiningScreen';
import MediasoupConferenceComponent from './MediasoupConferenceComponent';
import TopicsComponent from './TopicsComponent';
import SideMenuComponent from './SideMenuComponent';
import { GlobalContext } from "../../contexts/globalContext";
import RegisterNewVisitor from './RegisterNewVisitor';
import PdfFileComponent from '../../files/PdfFileComponent';
import ImageFileComponent from '../../files/ImageFileComponent';
import VideoFileComponent from '../../files/VideoFileComponent';
import videoSource from '../../graphics/big wave fundal loop_6.mp4';
import { AddConferenceProvider } from "../../contexts/addConferenceContext";

const ConferenceScreenComponent = () => {
    const { setCurrentTopic, currentTopic, waitingForJoining, setWaitingForJoining, setConferencePaused, currentParticipant, sharedFiles, openedFiles, conferenceSocketRef, setOpenedFiles, setSharedFiles, currentConference, appHeight, appWidth } = useContext(GlobalContext);

    useEffect(() => {

        setOpenedFiles([]);
        setSharedFiles([]);

        return () => {
            conferenceSocketRef.current?.off('UPDATE_SHARED_DATA')
            conferenceSocketRef.current?.off('PAUSE_CONFERENCE')
            conferenceSocketRef.current?.off('PLAY_CONFERENCE')
            conferenceSocketRef.current?.off('UPDATE_CURRENT_TOPIC')
        }
    }, [])

    useEffect(() => {
        if (conferenceSocketRef.current) {
            conferenceSocketRef.current.on('PAUSE_CONFERENCE', function () {
                setConferencePaused(true);
                setWaitingForJoining(true);

            });

            conferenceSocketRef.current.on('PLAY_CONFERENCE', function () {
                setConferencePaused(false);
                setWaitingForJoining(false);

            });

            conferenceSocketRef.current.on('UPDATE_SHARED_DATA', (updatedShareData) => {
                setSharedFiles([]);
                setSharedFiles(updatedShareData);

                currentConference.shared_data = JSON.stringify(updatedShareData);
            })

            var aux_topic = currentConference.topics.find(topic => topic._id == currentConference.currentTopic);
            // console.log("setCurrentTopic 1");
            setCurrentTopic(aux_topic);

            conferenceSocketRef.current.on('UPDATE_CURRENT_TOPIC', (topicId) => {
                console.log('UPDATE_CURRENT_TOPIC');

                if (currentConference.type != "Presentation") {
                    setSharedFiles([]);
                }
                if (topicId) {
                    var aux_topic = currentConference.topics.find(topic => topic._id == topicId);
                    console.log("setCurrentTopic 2");
                    console.log(aux_topic);
                    setCurrentTopic(aux_topic);
                    currentConference.currentTopic = aux_topic._id;
                } else {
                    //setCurrentModeratorStream(null);
                    setCurrentTopic(null);
                    currentConference.currentTopic = null;
                }
            })
        }
    }, [conferenceSocketRef.current])

    useEffect(() => {
        if (!waitingForJoining) {
            setSharedFiles([]);

            if (currentConference.shared_data) {
                setSharedFiles(JSON.parse(currentConference.shared_data));
            }

        }
    }, [waitingForJoining])

    const GetFileComponent = (file, shared) => {
        switch (file.type) {
            case "pdf":
                return <PdfFileComponent key={file.uid} shared={shared} file={file}></PdfFileComponent>
            case "image":
                return <ImageFileComponent key={file.uid} shared={shared} file={file}></ImageFileComponent>
            case "video":
                return <VideoFileComponent key={file.uid} shared={shared} file={file}></VideoFileComponent>
            default:
                return "";
        }
    }

    return (
        <div className={styles.component}>
            <video id="background-video" autoPlay loop playsInline muted style={{ position: "absolute", height: `${appHeight}px`, width: `${appWidth}px` }}>
                <source src={videoSource} type="video/mp4" />
            </video>
            {currentParticipant && <SocketManagerConference></SocketManagerConference>}

            {waitingForJoining && !currentParticipant && <RegisterNewVisitor></RegisterNewVisitor>}

            {waitingForJoining && currentParticipant && <WaitingForJoiningScreen SetWaitingForJoining={setWaitingForJoining}></WaitingForJoiningScreen>}
            {!waitingForJoining && <MediasoupConferenceComponent SetWaitingForJoining={setWaitingForJoining} />}


            {/* currentConference?.topics[0].moderator._id == currentParticipant._id &&  */}
            {!waitingForJoining && (currentConference?.type == "Conference" || (currentConference?.type == "Presentation" && currentConference?.topics[0].moderator._id == currentParticipant._id)) && <TopicsComponent SetWaitingForJoining={setWaitingForJoining} />}

            {!waitingForJoining && <AddConferenceProvider><SideMenuComponent SetWaitingForJoining={setWaitingForJoining} /></AddConferenceProvider>}

            {/* {!waitingForJoining && currentParticipant._id != currentTopic?.moderator._id && sharedFiles.map((file) => ( */}
            {!waitingForJoining && sharedFiles.map((file) => (
                GetFileComponent(file, true)
            ))}

            {!waitingForJoining && openedFiles.map((file) => (
                GetFileComponent(file, false)
            ))}

            {!waitingForJoining && currentTopic && <div style={{ width: "100%",  position: "absolute", display:"flex", justifyContent:"center" }}>
                <div style={{backgroundColor:"#292148", padding:"30px 60px", borderRadius:"0px 0px 10px 10px", font: "normal normal 600 50px/44px Bio Sans", color: "#C3C2CA"}}>{currentTopic.name}</div>    
            </div>}
        </div>
    );
}

export default ConferenceScreenComponent;