import { useRef, useContext, useState, useEffect } from 'react';
import styles from './AddModeratorPopup.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../../keyboard/InputTextComponent';
import KeyboardComponent from '../../keyboard/KeyboardComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'

import { AddConferenceContext } from '../../../contexts/addConferenceContext';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';

import InitialsCircleComponent from '../../commons/InitialsCircleComponent';


const AddModeratorPopup = () => {

    const inputRef = useRef()
    const keyboardRef = useRef()
    const usersRef = useRef()
    const filteredUsersRef = useRef()

    const [attachedParticipantsList, setAttachedParticipantsList] = useState([])
    const [selectedFilter, setSelectedFilter] = useState("All")

    const [filteredUsers, setFilteredUsers] = useState([])

    const { language, socketRef } = useContext(GlobalContext);
    const { editConference } = useContext(ProjectDetailsContext)
    const { setAddModerator, setSelectedModerator } = useContext(AddConferenceContext);


    const filterOptions = [
        "All", "Member", "Guest", "Partner"
    ]

    useEffect(() => {
        socketRef.current.emit('CONFERENCE_GET_USERS_FROM_CONFERENCE', editConference._id, callback => {
            setAttachedParticipantsList(callback.response)
            usersRef.current = callback.response
            setFilteredUsers(callback.response)
        })
    }, [])

    const handleChangeRole = (change) => {
        setSelectedFilter(change)

        console.log(change)

        let result = usersRef.current.filter(user => user.participant.role.includes(change));

        if (change === "MEMBER") {
            let adminDocs = usersRef.current.filter(user => user.participant.role.toLowerCase().includes("administrator"));
            result = [...result, ...adminDocs];
        }

        if(change === 'ALL') {
            result = usersRef.current
        }

        console.log(result)

        inputRef.current.value = ""
        setFilteredUsers(result);
        filteredUsersRef.current = result
    }


    return (
        <div className={styles.moderatorBox} style={{ zIndex: GetMaxZIndex() + 1 }}>
            <ClosePageButtonComponent setClose={() => setAddModerator(false)} />
            Select moderator
            <div className={styles.participantsFilterSearch} >
                <div className={styles.filter}>
                    {filterOptions.map((option, index) => <div key={index} className={selectedFilter === option ? styles.selectedTag : styles.tag} onClick={() => handleChangeRole(option.toUpperCase())}>
                        {option.toUpperCase() !== "ALL" ? `${option}s` : option}
                    </div>)}
                </div>
                <InputTextComponent ref={inputRef} fontSize="30" width="700px" marginLeft="0px" keyboardRef={keyboardRef} value="" styleType='SEARCHPOPUP'
                    placeHolder={language.add_participants.Search} />
                <FontAwesomeIcon className={styles.iconMagnifyingGlass} icon={byPrefixAndName.fal['magnifying-glass']} />

            </div>
            <div className={styles.participantsListAndAdd}>
                <div className={styles.participantsList}>
                    { filteredUsers?.map((attachedParticipant) => <div key={attachedParticipant._id} className={styles.participantCard} onClick={() => {
                        setSelectedModerator(attachedParticipant.participant)
                        setAddModerator(false)
                    }}>
                        <InitialsCircleComponent name={attachedParticipant.participant.name} style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10 }} />
                        <div className={styles.participantDetails}>
                            {attachedParticipant.participant.role}
                            <div className={styles.participantName}>{attachedParticipant.participant.name}</div>
                            {attachedParticipant.participant.function?.name} {attachedParticipant.function ? "|" : ""} {attachedParticipant.participant.department?.name}
                        </div>
                    </div>)}

                </div>
                <div className={styles.participantListShadow} style={{ zIndex: GetMaxZIndex() + 1 }} />
            </div>
        </div>

    );

}

export default AddModeratorPopup;