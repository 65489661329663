import { useRef, useContext, useEffect, createRef } from 'react';

import { GlobalContext } from "../../../contexts/globalContext"
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { AddConferenceContext } from "../../../contexts/addConferenceContext"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './FilesDetailsConferenceDetailsComponent.module.css'

import DetailsCardComponent from './DetailsCardComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent'

import MoreButtonComponent from '../../commons/MoreButtonComponent'


import imgAdd from '../../../graphics/Popups/Icon Files Add.png'
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';


const FilesDetailsConferenceDetailsComponent = () => {

    const fileIdRef = useRef()
    const fileIndexRef = useRef()

    const { socketRef, appHeight } = useContext(GlobalContext)
    const { } = useContext(ProjectDetailsContext)
    const { setOpenFiles, moreClicked, setMoreClicked, setDeleteClicked, fileList, setFileList, openFiles, setConferenceAddFiles, moreMenuTimer } = useContext(AddConferenceContext)

    const moreButtonRefs = useRef(fileList.map(() => createRef()));
    

    const icon = (type) => {
        if (type.includes("image")) {
            return "file-image"
        } else if (type.includes("video")) {
            return "file-video"
        } else if (type.includes("pdf")) {
            return "file-pdf"
        } else if (type.includes("word")) {
            return "file-word"
        } else if (type.includes("excel")) {
            return "file-excel"
        } else {
            return "file-lines"
        }
    };

    useEffect(() => {
        let type = "Subtopic"
        if (openFiles.moderator) {
            type = "Topic"
        }
        socketRef.current.emit("CONFERENCE_GET_TOPIC_ATTACHED_DOCUMENTS", {
            parentId: openFiles._id,
            type
        }, callback => {
            if (!callback.error) {
                setFileList(callback.response)
            }
        })
    }, [openFiles])

    useEffect(() => {
        if (fileList.length > moreButtonRefs.current.length) {
            for (let i = moreButtonRefs.current.length; i < fileList.length; i++) {
                moreButtonRefs.current.push(createRef());
            }
            console.log(moreButtonRefs.current)
        } else {
            moreButtonRefs.current = fileList.map(() => createRef());
        }
        
    }, [fileList]);

    function handleClick(fileId, index) {
        fileIdRef.current = fileId;
        fileIndexRef.current = index;
        if (moreClicked?.shown && fileId === moreClicked.id) {
            setMoreClicked({});
        } else {
            if (!moreMenuTimer) {
                const rect = moreButtonRefs.current[fileIndexRef.current].current.getBoundingClientRect();
                setMoreClicked({
                    ...moreClicked,
                    shown: true,
                    id: fileId,
                    clickRemove: handleDelete,
                    x: rect.x,
                    y: rect.y - 428
                });
            }
        }
    }

    const handleDelete = () => {
        const rect = moreButtonRefs.current[fileIndexRef.current].current.getBoundingClientRect();
        setDeleteClicked({
            shown: true,
            funct: handleDeleteFile,
            name: "File",
            x: rect.x,
            y: rect.y,
            onClose: () => setDeleteClicked(),
        });
    };

    const handleDeleteFile = () => {
        socketRef.current.emit(
            "PROJECT_DELETE_DOCUMENT",
            {
                parentId: openFiles._id,
                refType: openFiles.moderator ? "Topic" : 'Subtopic',
                projectFileId: fileIdRef.current,
            },() => {
                setMoreClicked({});
                setDeleteClicked(false);
                let type = "Subtopic"
                if (openFiles.moderator) {
                    type = "Topic"
                }
                socketRef.current.emit("CONFERENCE_GET_TOPIC_ATTACHED_DOCUMENTS", {
                    parentId: openFiles._id,
                    type
                }, callback => {
                    if (!callback.error) {
                        setFileList(callback.response)
                    }
                })
            }
        );
    };

    return (
        <>
            <DarkButtonComponent style={{ width: '98%', position: 'absolute', top: 92, marginBottom: 40, zIndex: GetMaxZIndex() + 1 }} onClick={() => setConferenceAddFiles(true)}>
                <FontAwesomeIcon className={styles.iconPlus} icon={byPrefixAndName.fas['plus']} /> Add files
            </DarkButtonComponent>
            <DetailsCardComponent title="Files" height={appHeight - 583}>
                <div style={{ width: "100%" }}>
                    <div className={styles.openFiles}>
                        <div className={styles.noFiles} style={{ justifyContent: fileList.length === 0 ? "center" : "", alignItems: fileList.length === 0 ? "center" : "" }}>
                            {fileList.length === 0 && <>
                                <img src={imgAdd} alt="icon add" className={styles.imgAdd} onClick={() => setConferenceAddFiles(true)} />
                                <div className={styles.description}>Add files to topic</div>
                                {openFiles.name}
                            </>}
                            {fileList.length > 0 && fileList.map((file, index) =>
                                <div key={index} className={styles.fileCard}>
                                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas[icon(file.docsFile.type)]} />
                                    <div className={styles.fileDetails}>
                                        {file.docsFile.name}
                                        <div className={styles.fileDescription}>
                                            Added by:
                                            <div className={styles.fileAddedBy}>{file.addedBy?.name}</div>
                                        </div>
                                    </div>
                                    <div style={{position: "absolute", top: -50, right: 0}}>
                                        <MoreButtonComponent ref={moreButtonRefs.current[index]} clicked={moreClicked} setClicked={() => handleClick(file._id, index)} id={file._id} />
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </DetailsCardComponent>
        </>
    );

}

export default FilesDetailsConferenceDetailsComponent;