import React, { forwardRef } from 'react';
import { useGesture } from 'use-gesture-pack';
import { scale, rotateDEG, translate, compose, toString, identity, fromString } from 'transformation-matrix';

const CustomMultitouchComponent = forwardRef((props, ref) => {

    //var ref = useRef();
    const { dragGesture = false, swipeGesture = true, multiGesture = true, touchAndHoldGesture = true, pinchGesture = true, tapGesture = false, options, children, x = 0, y = 0 } = props
    const { appWidth = 3840, appHeight = 2160, withInertia = true, minScale = 0.5, maxScale = 50, touchAndHoldDuration = 1500, bringToFront = true } = options ? options : {};


    let lastMouseX = 0;
    let lastMouseY = 0;
    let velocity = { x: 0, y: 0 };

    // const handleMouseDown = (event) => {
    //     event.preventDefault();
    //     StopAnimation();

    //     lastMouseX = event.clientX;
    //     lastMouseY = event.clientY;

    //     const handleMouseMove = (moveEvent) => {
    //         const dx = moveEvent.clientX - lastMouseX;
    //         const dy = moveEvent.clientY - lastMouseY;

    //         velocity = { x: dx, y: dy }; // Update velocity

    //         lastMouseX = moveEvent.clientX;
    //         lastMouseY = moveEvent.clientY;

    //         var mat = identity();

    //         if (ref.current.style.transform !== '') {
    //             mat = fromString(ref.current.style.transform);
    //         }

    //         const trans = translate(dx, dy);
    //         const final = compose(trans, mat);
    //         ref.current.style.transform = toString(final);
    //     };

    //     const handleMouseUp = () => {
    //         document.removeEventListener('mousemove', handleMouseMove);
    //         document.removeEventListener('mouseup', handleMouseUp);

    //         if (withInertia) {
    //             ApplyInertia(ref, velocity, appWidth, appHeight);
    //         } else {
    //             CheckIfOutsideBounds(ref, appWidth, appHeight);
    //         }
    //     };

    //     document.addEventListener('mousemove', handleMouseMove);
    //     document.addEventListener('mouseup', handleMouseUp);
    // };


    useGesture(
        {
            onDragStart: () => {
                props.onDragStart?.();
                StopAnimation();
            },
            onDrag: ({ point, dx, dy }) => {
                var mat = identity();

                if (ref.current.style.transform !== '') {
                    mat = fromString(ref.current.style.transform);
                }

                var trans = translate(dx, dy);
                var final = compose(trans, mat);
                ref.current.style.transform = toString(final);
            },
            onPinchStart: () => {
                props.onPinchStart?.();
                StopAnimation();
            },
            onPinch: ({ dx, dy, angle, centerPoint, scale_factor, transformationPoint, points }) => {
                var mat = identity();

                if (ref.current.style.transform !== '') {
                    mat = fromString(ref.current.style.transform);
                }

                var trans = translate(dx, dy);
                var rotate = rotateDEG(angle, transformationPoint.x, transformationPoint.y);

                var currentScale = GetMatrixScale(mat)

                if (currentScale * scale_factor > maxScale || currentScale * scale_factor < minScale) {
                    if (currentScale * scale_factor < minScale && props.onMinScaleReached) {
                        props.onMinScaleReached();
                    }
                    scale_factor = 1;
                }

                var scaleFunction = scale(scale_factor, scale_factor, transformationPoint.x, transformationPoint.y);

                //var final = compose(rotate, scaleFunction, trans, mat);
                var final = compose(scaleFunction, trans, mat);

                ref.current.style.transform = toString(final);
            },
            onMultiStart: () => {
                StopAnimation();
            },
            onMulti: ({ dx, dy, points, centerPoint }) => {
                if (multiGesture === true) {
                    var mat = identity();

                    if (ref.current.style.transform !== '') {
                        mat = fromString(ref.current.style.transform);
                    }

                    var trans = translate(dx, dy);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);
                }
            },
            onSwipe: (direction) => {
                props.onSwipe?.(direction);
            },
            onDragInside: (options) => {
                props.onDragInside?.(options);
            },
            onTouchAndHoldEnd: () => {
                props.onTouchAndHoldFinished?.();
            },
            onGestureEnded: ({ velocity }) => {
                props.onGestureEnded?.();
                if (ref.current) {
                    if (withInertia) {
                        ApplyInertia(ref, velocity, appWidth, appHeight);
                    } else {
                        CheckIfOutsideBounds(ref, appWidth, appHeight);
                    }
                }
            },
            onTapped: () => {
                props.onTapped?.();
            }
        },
        {
            target: ref,
            dragGesture,
            swipeGesture,
            pinchGesture,
            multiGesture,
            tapGesture,
            touchAndHoldGesture,
            appWidth,
            appHeight,
            touchAndHoldDuration,
            bringToFront
        }
    );

    function StopAnimation() {
        var computedStyle = window.getComputedStyle(ref.current);
        var aux_transform = computedStyle.getPropertyValue('transform');
        ref.current.style.transform = aux_transform;
        ref.current.style.transition = "";
    }

    function ApplyInertia(target, velocity, appWidth, appHeight) {
        var mat = fromString(target.current.style.transform);
        var trans = translate(velocity.x, velocity.y);


        var length = Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y);

        var animDuration = length * 300 < 300 ? 300 : (length * 300 > 800 ? 800 : length * 300);

        target.current.style.transition = "transform " + animDuration + "ms";
        target.current.style.transitionTimingFunction = "ease-out";

        var final = compose(trans, mat);
        target.current.style.transform = toString(final);


        setTimeout(() => {
            CheckIfOutsideBounds(target, appWidth, appHeight);
        }, animDuration);
    }

    function CheckIfOutsideBounds(target, appWidth, appHeight) {
        if (target.current) {
            var oxDivider = window.innerWidth / appWidth;
            var oyDivider = window.innerHeight / appHeight;
            target.current.style.transition = "";
            //const { width, height, x, y } = target.current.getBoundingClientRect();
            const { width, height, x, y } = target.current.childNodes[0].getBoundingClientRect();

            if (x + width / 2 < 0) {

                target.current.style.transition = "transform 500ms";
                target.current.style.transitionTimingFunction = "ease-in";

                var mat = fromString(target.current.style.transform);
                var trans = translate((-(x + width / 2)) / oxDivider, 0);
                var final = compose(trans, mat);
                target.current.style.transform = toString(final);

                // props.onBounceBackEnded?.(target.current.style.transform );

                setTimeout(() => {
                    target.current.style.transition = "";
                }, 500);
            } else if (x + width / 2 > window.innerWidth) {
                target.current.style.transition = "transform 500ms";
                target.current.style.transitionTimingFunction = "ease-in";

                var mat = fromString(target.current.style.transform);
                var trans = translate(- ((x + width / 2) - window.innerWidth) / oxDivider, 0);
                var final = compose(trans, mat);
                target.current.style.transform = toString(final);

                // props.onBounceBackEnded?.(target.current.style.transform );

                setTimeout(() => {
                    target.current.style.transition = "";
                }, 500);
            } else if (y + height / 2 < 0) {
                props.onBounceBackStarted?.(target.current.style.transform);
                target.current.style.transition = "transform 500ms";
                target.current.style.transitionTimingFunction = "ease-in";

                var mat = fromString(target.current.style.transform);
                var trans = translate(0, (-(y + height / 2)) / oyDivider);
                var final = compose(trans, mat);
                target.current.style.transform = toString(final);

                props.onBounceBackEnded?.(final, width, height, target);

                setTimeout(() => {
                    target.current.style.transition = "";
                }, 500);
            } else if (y + height / 2 > window.innerHeight) {
                target.current.style.transition = "transform 500ms";
                target.current.style.transitionTimingFunction = "ease-in";

                var mat = fromString(target.current.style.transform);
                var trans = translate(0, - ((y + height / 2) - window.innerHeight) / oyDivider);
                var final = compose(trans, mat);
                target.current.style.transform = toString(final);

                // props.onBounceBackEnded?.(target.current.style.transform );

                setTimeout(() => {
                    target.current.style.transition = "";
                }, 500);
            }
        }
    }

    function GetMatrixScale(mat) {
        return Math.sqrt(mat.a * mat.a + mat.b * mat.b)
    }

    return (
        <div ref={ref} style={{ transition:"transform 0.5s", position: 'absolute', width: "10px", height: "10px", transform: `matrix(1, 0, 0, 1, ${x}, ${y})`, backgroundColor: "transparent" }}>
            {children}
        </div>
    );
})

export default CustomMultitouchComponent;