import { useRef, useContext, useState, useEffect } from 'react';
import styles from './CS_AttachedParticipantsListComponent.module.css'

import { GlobalContext } from "../../contexts/globalContext";
import { AddConferenceContext } from '../../contexts/addConferenceContext';


import InitialsCircleComponent from '../commons/InitialsCircleComponent';
import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';


const CS_AttachedParticipantsListComponent = ({setDisplayedParticipants, attachedParticipants, setAttachedParticipants, allDisplayedDesks, isRemote, allParticipantsRef}) => {

    const [spaces, setSpaces] = useState([ 0, 1, 2, 3, 4, 5, 6, 7, 8])

    const { conferenceSocketRef, setAlertMessage } = useContext(GlobalContext);
    const { uniqueModerators } = useContext(AddConferenceContext);


    useEffect(() => {
        if(attachedParticipants.length === spaces.length){
            setSpaces((arr) => [...arr, attachedParticipants.length])
        }
    }, [attachedParticipants])


    const removeParticipant = (assignedParticipant, desk) => {

        if(uniqueModerators.some(moderator => moderator._id === assignedParticipant.participant._id)){
            setAlertMessage({ type: "ERROR", message: "You can't remove a participant that is a moderator of the conference." })
        } else {
            // cred ca trebuie call diferit pt remote si apoi se rezolva problema, acum daca scot ceva de la remote este returnat un array gol
            conferenceSocketRef.current.emit("CONFERENCE_REMOVE_PARTICIPANT_FROM_DESK", { 
                attachedParticipantId: assignedParticipant._id
            }, callback => {
                if(!callback.error){
                    conferenceSocketRef.current.emit("DESK_PARTICIPANT_CHANGED", desk._id);
                    setAttachedParticipants(callback.response)
                    // console.log(callback.response)
                    setDisplayedParticipants((prevParticipants) => [
                        ...prevParticipants,
                        assignedParticipant.participant
                    ]);
                }
            })
        }
            
        
    }




    return (
        <div className={styles.addedParticipantsList}>
            {!isRemote && allDisplayedDesks.map((desk, index) => {
                const assignedParticipant = attachedParticipants.find(
                    (attached) => attached.desk?._id === desk._id || attached.desk === desk._id
                );

                return (
                    <div className={styles.positionCard} key={desk._id} style={{
                        opacity: assignedParticipant ? 1 : 0.4, 
                        backgroundColor: assignedParticipant ? "transparent" : ""}}>
                        {assignedParticipant ? (<div className={styles.assignedParticipant} onClick={() => removeParticipant(assignedParticipant, desk)}>
                            <InitialsCircleComponent name={assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"}
                                style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10, marginTop: 20 }} />
                            <div className={styles.participantDetails}>
                                {assignedParticipant.participant.role}
                                <div className={styles.participantName}>{assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"} </div>
                                {assignedParticipant.participant.function?.name} {assignedParticipant.participant.function ? "|" : ""} {assignedParticipant.participant.department?.name}
                            </div>
                            <div className={styles.assignedDeskNumber} style={{zIndex: GetMaxZIndex() + 1}}>{desk.number}</div>
                        </div>
                        ) : (<div className={styles.numberedCircle}>{desk.number}</div>)}
                    </div>
                );
            })}
            {isRemote && spaces.map((space) => {
                let assignedParticipant = null

                if(space < attachedParticipants.length){
                    assignedParticipant = attachedParticipants[space]
                }

                // console.log(space, assignedParticipant, attachedParticipants.length)

                return (
                    <div className={styles.positionCard} key={space} style={{opacity: assignedParticipant ? 1 : 0.4}}>
                        {assignedParticipant && <div className={styles.assignedParticipant} onClick={() => removeParticipant(assignedParticipant)}>
                            <InitialsCircleComponent name={assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"}
                                style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10, marginTop: 20 }} />
                            <div className={styles.participantDetails}>
                                {assignedParticipant.participant.role}
                                <div className={styles.participantName}>{assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"} </div>
                                {assignedParticipant.participant.function?.name} {assignedParticipant.participant.function ? "|" : ""} {assignedParticipant.participant.department?.name}
                            </div>
                        </div>}
                    </div>
                );
            })}
        </div>
    );

}

export default CS_AttachedParticipantsListComponent;