import { useRef, useContext, useState, useEffect, createRef } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { AddConferenceContext } from '../../../contexts/addConferenceContext';
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import styles from './ConferenceAddNewTopicComponent.module.css'
import uuid from 'react-uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../../keyboard/InputTextComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'

// import topicsAdd from '../../../graphics/Popups/Icon Folders Add.png'
import LightButtonComponent from '../../commons/LightButtonComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';
import RightBodyComponent from '../common/RightBodyComponent';
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import OutsideGlowComponent from '../common/OutsideGlowComponent';
import topicsAdd from '../../../graphics/Popups/Icon Conferinta Add.png'
import iconEdit from '../../../graphics/Popups/Icon Edit Topic.png'

import KeyboardComponent from '../../keyboard/KeyboardComponent';
import AddModeratorPopup from './AddModeratorPopup';

const ConferenceAddNewTopicComponent = () => {

    const nameRef = useRef()
    const keyboardRef = useRef()
    const subtopicRef = useRef()

    const [topicIsOnFocus, setTopicIsOnFocus] = useState(false)
    const [indexSubtopic, setIndexSubtopic] = useState(-1)
    const [subtopicIsOnFocus, setSubtopicIsOnFocus] = useState(false)
    const [subtopicMapIsOnFocus, setSubtopicMapIsOnFocus] = useState(false)

    const { socketRef, currentUser, appWidth, appHeight } = useContext(GlobalContext);
    const { editConference, setEditConference } = useContext(ProjectDetailsContext)
    const { setAddNewTopic, selectedTopic, addModerator, setAddModerator, selectedModerator, setSelectedModerator, setUniqueModerators, setOpenFiles } = useContext(AddConferenceContext);

    const [firstClick, setFirstClick] = useState(selectedTopic ? true : false)
    const [currentSubtopicList, setCurrentSubtopicList] = useState(selectedTopic && selectedTopic.subtopics ? selectedTopic.subtopics : [])
    const fileRefs = useRef(currentSubtopicList.map(() => createRef()));

    useEffect(() => {
        if (topicIsOnFocus) {
            setFirstClick(true)
        }
    }, [topicIsOnFocus])

    useEffect(() => {
        if (selectedTopic) {
            setSelectedModerator(selectedTopic.moderator)
        }
        return () => {
            setSelectedModerator()
        }
    }, [])

    function handleTopicAdd() {
        let subtopicsList = []
        subtopicsList = currentSubtopicList.map(subtopic => subtopic.name)
        socketRef.current.emit('CONFERENCE_UPDATE_TOPIC', {
            conferenceId: editConference._id,
            topicId: selectedTopic ? selectedTopic._id : null,
            newData: {
                name: nameRef.current?.value ? nameRef.current?.value : "Default Title",
                moderator: selectedModerator._id,
                subtopics: subtopicsList
            }
        }, callback => {
            console.log(callback)
            if (!callback.error) {
                if (!callback.error) {
                    if (selectedTopic) {
                        setEditConference((prev) => ({
                            ...prev,
                            moderators: callback.response.moderators,
                            topics: prev.topics.map((topic) =>
                                topic._id === selectedTopic._id ? callback.response.updatedTopic : topic
                            ),
                        }))
                        setOpenFiles(callback.response.updatedTopic)
                    } else {
                        setEditConference((prev) => ({
                            ...prev,
                            moderators: callback.response.moderators,
                            topics: [...prev.topics, callback.response.updatedTopic],
                        }));
                    }
                    setAddNewTopic(false)
                }
            }
        })
    }

    function handleSubtopicAdd() {
        let subtopicName = subtopicRef.current?.value ? subtopicRef.current?.value : "Default Title"
        setCurrentSubtopicList(currentSubtopicList => [...currentSubtopicList, { name: subtopicName, _id: uuid() }])
        fileRefs.current = [...fileRefs.current, createRef()]
        subtopicRef.current.value = ""
    }

    function handleDeleteSubtopic(id) {
        if (selectedTopic) {
            socketRef.current.emit("CONFERENCE_DELETE_SUBTOPIC", id, selectedTopic._id, selectedTopic._id)
        }
        let subtopicsList = currentSubtopicList
        let filteredSubtopicsList = subtopicsList.filter((subtopic) => subtopic._id !== id);
        setCurrentSubtopicList(filteredSubtopicsList)
    }

    useEffect(() => {
        if (indexSubtopic !== -1 && !subtopicMapIsOnFocus) {
            let updatedSubtopics = currentSubtopicList.map((subtopic) => {
                if (subtopic._id === currentSubtopicList[indexSubtopic]._id) {
                    return {

                        ...subtopic, name: fileRefs.current[indexSubtopic].current?.value ? fileRefs.current[indexSubtopic].current?.value : "Default Title"
                    };
                }
                return subtopic;
            })
            setCurrentSubtopicList(updatedSubtopics)
        }
    }, [subtopicMapIsOnFocus]);

    function closePopup() {
        setAddNewTopic()
    }




    return (
        <PopupBaseComponent>
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.66} width="2140px" height="610px" s="1" />
            <PopupBodyComponent style={{ width: 2455, height: 1301 }}>
                <ClosePageButtonComponent setClose={closePopup} />
                <LeftBodyComponent image={selectedTopic? iconEdit : topicsAdd} title= {selectedTopic? "Edit Topic" :  "New Topic"} description={selectedTopic? "Modify the topic name, add, edit, or delete subtopics, and update the moderator." :"Enter the topic name and assign a moderator (required). You can also add subtopics (optional)."} />
                <RightBodyComponent>
                    <div className={styles.body_header} >
                        Topic Name *
                    </div>
                    {topicIsOnFocus && <OutsideGlowComponent style={{ top: 221, left: 1152, width: 1347 }} />}
                    <InputTextComponent ref={nameRef} keyboardRef={keyboardRef} value={selectedTopic ? selectedTopic.name : ""} setIsOnFocus={setTopicIsOnFocus} placeHolder="Enter topic name here" styleType='POPUP' fontSize="40px" width="1300px" />
                    {!topicIsOnFocus && <FontAwesomeIcon className={styles.iconPenTopic} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.subtopics}>
                        <div className={styles.subtopicsHeader}>
                            Subtopics
                            <div className={styles.subtopicsOptional}>(Optional)</div>
                        </div>

                        <div className={styles.subtopics_map}>

                            {currentSubtopicList.length > 0 && currentSubtopicList.map((subtopic, index) =>
                                <div key={index} className={styles.subtopicsAdd}>
                                    {indexSubtopic === index && subtopicMapIsOnFocus && <OutsideGlowComponent style={{ top: -8, left: -8, width: 959 }} />}

                                    <InputTextComponent onChange ref={fileRefs.current[index]} keyboardRef={keyboardRef} setIndex={setIndexSubtopic} index={index}
                                        setIsOnFocus={setSubtopicMapIsOnFocus} value={subtopic.name} placeHolder="Enter subtopic name here" styleType={'POPUP'}
                                        fontSize="40px" width={indexSubtopic === index && subtopicMapIsOnFocus ? "921px" : "1260px"} />
                                    <LightButtonComponent onClick={() => handleDeleteSubtopic(subtopic._id)} style={{ width: 300 }}>
                                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['trash']} />
                                        Delete
                                    </LightButtonComponent>

                                    {!subtopicMapIsOnFocus && <FontAwesomeIcon className={styles.iconEditElement} icon={byPrefixAndName.fal['pen']} />}

                                </div>)}
                            <div className={styles.subtopicsAdd}>
                                {subtopicIsOnFocus && <OutsideGlowComponent style={{ top: -8, left: -8, width: 959 }} />}
                                {nameRef.current?.value !== "" && firstClick &&
                                    <><InputTextComponent ref={subtopicRef} keyboardRef={keyboardRef} setIsOnFocus={setSubtopicIsOnFocus} value="" placeHolder="Enter subtopic name here" styleType={'POPUP'} fontSize="40px" width={subtopicIsOnFocus ? "921px" : "1260px"} />
                                        <LightButtonComponent onClick={handleSubtopicAdd} style={{ width: subtopicIsOnFocus ? 300 : 0 }}>
                                            {subtopicIsOnFocus && <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} />}
                                            {subtopicIsOnFocus && "Add"}
                                        </LightButtonComponent></>}
                                {!subtopicIsOnFocus && <FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fal['pen']} />}

                            </div>
                        </div>
                    </div>
                    <div className={styles.title}>Moderator *</div>
                    {!selectedModerator ? <LightButtonComponent style={{ marginLeft: 0, width: 430 }} onClick={() => {
                        if (currentUser?.role.toLowerCase() === "administrator")
                            setAddModerator(true)
                    }}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> Add moderator
                    </LightButtonComponent> :
                        <div className={styles.moderatorName} onClick={() => {
                            if (currentUser?.role.toLowerCase() === "administrator")
                                setAddModerator(true)
                        }}>
                            {selectedModerator.name}
                        </div>}
                    <div className={styles.explanation}>⚠️ <span style={{ opacity: 0.5 }}>Fields marked with * are mandatory</span></div>
                    <FooterButtonsComponent
                        darkClick={closePopup}
                        lightText={selectedTopic ? "Update" : "Create"}
                        lightClick={(nameRef.current?.value === "" || !nameRef.current?.value) && !topicIsOnFocus ? () => { } : handleTopicAdd}
                        isOpaque={(nameRef.current?.value === "" || !nameRef.current?.value) && !topicIsOnFocus}
                    />

                </RightBodyComponent>
            </PopupBodyComponent>
            {addModerator && <AddModeratorPopup />}
        </PopupBaseComponent>
    );

}

export default ConferenceAddNewTopicComponent;