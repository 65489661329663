import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from './PdfFileComponent.module.css'
import { GlobalContext } from "../contexts/globalContext"

import PdfIcon from '../graphics/conference/file-pdf.png'
import LoadingJson from '../graphics/json-animations/spinner-loading.json'

import Lottie from 'react-lottie-player'
import { PdfComponent } from 'use-gesture-pack';
import CustomMultitouchComponent from "./CustomMultitouchComponent"

import ShareIcon from "../graphics/conference/icon-share-btn.svg"

const PdfFileComponent = ({ file, shared }) => {
    const ref = useRef(null);
    const mtRef = useRef(null);
    const { sharedFiles, setSharedFiles, setOpenedFiles, openedFiles, appHeight, appWidth, currentParticipant, currentTopic, conferenceSocketRef, currentConference, videoStreams } = useContext(GlobalContext);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const mustBeDeletedRef = useRef();
    const updateRequiredRef = useRef();
    const sharedFilesRef = useRef();
    // Nou
    const [showParticipants, setShowParticipants] = useState(false);

    const [isVisible, setIsVisible] = useState(false);

    const pdfRef = React.useRef();

    const containerStyle = {
        width: `${isVisible ? width : 60}px`,
        height: `${isVisible ? height : 75}px`,
        overflow: 'hidden',
        transition: `width 0.5s ease, height 0.5s ease, transform 0.5s ease`,
        transformOrigin: 'center center',
        transform: `translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0)`,

        padding: `${isVisible ? '0px' : '0px'}`,
        backdropFilter: `${isVisible ? 'blur(10px)' : ''}`,
        backgroundColor: `${isVisible ? 'rgba(0, 0, 0, 0.3)' : 'transparent'}`,
    };

    const options = {
        appWidth,
        appHeight,
        maxScale: appHeight / 600,
        minScale: 0.5
    }

    const onDocumentLoaded = (data) => {
        setIsVisible(true)
        setWidth(data.width);
        setHeight(data.height);
    }

    useEffect(() => {
        mustBeDeletedRef.current = false;
        updateRequiredRef.current = true;
    }, [])

    const onMinScaleReached = () => {
        mustBeDeletedRef.current = true;

        setOpenedFiles(files => files.filter(a => a.uid !== file.uid));

        if (shared) {
            var filtrat = sharedFilesRef.current.filter(a => a.uid !== file.uid);
            setSharedFiles(filtrat);
            currentConference.shared_data = JSON.stringify(filtrat);
            conferenceSocketRef.current.emit("UPDATE_SHARE", currentConference._id, filtrat);
        }
    }

    const onSwipe = (direction) => {
        pdfRef.current.changePage(direction);

        setTimeout(() => {
            if (shared) {
                ShareDocument();
            } else {
                SaveOpenedFilesState();
            }
        }, 500)
    }

    const onGestureEnded = () => {
        if (shared) {
            if (!mustBeDeletedRef.current) {
                ShareDocument();
            }
        } else {
            SaveOpenedFilesState();
        }
    }

    const SaveOpenedFilesState = () => {
        updateRequiredRef.current = false;
        file.transform = mtRef.current?.style.transform;
        file.pageNum = pdfRef.current?.pageNum();

        setOpenedFiles(objs =>
            objs.map(item =>
                item.uid === file.uid ? file : item
            )
        );
    }

    const ShareDocument = () => {
        updateRequiredRef.current = false;
        sharedFilesRef.current = sharedFilesRef.current.filter(a => a.uid !== file.uid);

        file.transform = mtRef.current?.style.transform;
        file.pageNum = pdfRef.current?.pageNum();

        //Nou
        if (!file.sharedBy) {
            file.sharedBy = currentParticipant._id;
            file.sharedByName = currentParticipant.name;
        }        

        file.moderatedBy = currentParticipant._id;
        file.moderatedByName = currentParticipant.name;


        sharedFilesRef.current = [...sharedFilesRef.current, file];

        setOpenedFiles(devices => devices.filter(a => a.uid !== file.uid));

        currentConference.shared_data = JSON.stringify(sharedFilesRef.current);
        conferenceSocketRef.current.emit("UPDATE_SHARE", currentConference._id, sharedFilesRef.current);
    }

    useEffect(() => {
        if (shared) {
            if (currentParticipant._id == file.moderatedBy || currentParticipant._id == file.sharedBy) {
                mtRef.current.style.msTouchAction = "auto";
                mtRef.current.style.pointerEvents = "auto";
                mtRef.current.style.touchAction = "auto";

                ref.current.style.msTouchAction = "auto";
                ref.current.style.pointerEvents = "auto";
                ref.current.style.touchAction = "auto";

            } else {
                mtRef.current.style.msTouchAction = "none";
                mtRef.current.style.pointerEvents = "none";
                mtRef.current.style.touchAction = "none";

                ref.current.style.msTouchAction = "none";
                ref.current.style.pointerEvents = "none";
                ref.current.style.touchAction = "none";
            }
        }


        if (updateRequiredRef.current) {
            if (file.transform) {
                mtRef.current.style.transform = file.transform;
            }

            if (file.pageNum && pdfRef.current) {
                setTimeout(() => {
                    pdfRef.current.setPageNum(file.pageNum);
                }, 700)
            }

        } else {
            updateRequiredRef.current = true;
        }
    }, [file])

    useEffect(() => {
        sharedFilesRef.current = sharedFiles;
    }, [sharedFiles])

    // Nou
    const ChangeModerator = (moderatorId, moderatorName) => {
        updateRequiredRef.current = false;
        sharedFilesRef.current = sharedFilesRef.current.filter(a => a.uid !== file.uid);


        file.moderatedBy = moderatorId;
        file.moderatedByName = moderatorName;


        sharedFilesRef.current = [...sharedFilesRef.current, file];

        setOpenedFiles(devices => devices.filter(a => a.uid !== file.uid));

        currentConference.shared_data = JSON.stringify(sharedFilesRef.current);
        conferenceSocketRef.current.emit("UPDATE_SHARE", currentConference._id, sharedFilesRef.current);
        setShowParticipants(false);

    }

    return (
        <CustomMultitouchComponent ref={mtRef} dragGesture={false} x={file.x + 70} y={file.y + 35} width={isVisible ? width : 60} height={isVisible ? height : 75} onGestureEnded={onGestureEnded} onSwipe={onSwipe} onMinScaleReached={onMinScaleReached} options={options}>
            <div className={styles.touch_component} ref={ref} style={containerStyle}>
                {/* <PdfComponent ref={pdfRef} src={"https://cms.nextfusion.ro/" + file.path} onDocumentLoaded={onDocumentLoaded}> */}
                <PdfComponent ref={pdfRef} src={process.env.REACT_APP_CMS_URL + file.path} onDocumentLoaded={onDocumentLoaded}>


                </PdfComponent>

                {/* Nou */}
                {shared && <div style={{ position: "absolute", right: "20px", top: "20px", backgroundColor: "#000000", color: "white", padding: "5px" }}>Moderator: {file.moderatedByName}</div>}


                {!shared && (!currentTopic || currentParticipant._id == currentTopic?.moderator._id) &&

                    <img src={ShareIcon} style={{ position: "absolute", left: "10px", bottom: "10px", width: "120px" }} onClick={() => ShareDocument()} />

                }

                
                {/* Nou */}
                {shared && file.sharedBy == currentParticipant._id &&
                    <button onClick={() => setShowParticipants(true)} style={{ position: "absolute", left: "10px", bottom: "10px", width: "120px" }}>Change moderator</button>
                }
                {shared && file.sharedBy == currentParticipant._id && showParticipants &&
                    <div style={{ position: "absolute", left: "0px", top: "0px", width: "100%", height: "100%", backgroundColor: "red" }}>
                        {videoStreams.map((videoStream) => (
                            <button onClick={() => ChangeModerator(videoStream.participant._id, videoStream.participant.name)}>{videoStream.participant.name}</button>
                        ))}
                    </div>
                }

                <Lottie
                    loop
                    animationData={LoadingJson}
                    play
                    speed={1.9}
                    style={{ position: "absolute", left: "-13px", top: "-13px", width: 85, height: 100, opacity: `${isVisible ? 0 : 1}` }}
                />
                <img src={PdfIcon} style={{ position: "absolute", top: "0px", left: "0px", width: 60, height: 75, opacity: `${isVisible ? 0 : 1}` }} alt="icon" draggable="false" />

            </div>
        </CustomMultitouchComponent>
    );
}

export default PdfFileComponent;