import { useRef, useContext, useEffect, createRef } from 'react';
import { GlobalContext } from "../../../contexts/globalContext";
import { AddConferenceContext } from "../../../contexts/addConferenceContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import styles from './FilesDetailsConferenceDetailsComponent.module.css';
import DetailsCardComponent from './DetailsCardComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent';
import imgAdd from '../../../graphics/Popups/Icon Files Add.png';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';

const FilesDetailsConferenceDetailsComponent = () => {
    const fileIdRef = useRef();
    const fileIndexRef = useRef();
    const deleteRef = useRef([]);

    const { socketRef, appHeight, currentConference } = useContext(GlobalContext);
    const { setOpenFiles, moreClicked, setMoreClicked, setDeleteClicked, fileList, setFileList, openFiles, setConferenceAddFiles, moreMenuTimer } = useContext(AddConferenceContext);

    const icon = (type) => {
        switch (true) {
            case type.includes("image"): return "file-image";
            case type.includes("video"): return "file-video";
            case type.includes("pdf"): return "file-pdf";
            case type.includes("word"): return "file-word";
            case type.includes("excel"): return "file-excel";
            default: return "file-lines";
        }
    };

    useEffect(() => {
        let type = openFiles.moderator ? "Topic" : "Subtopic";
        socketRef.current.emit("CONFERENCE_GET_TOPIC_ATTACHED_DOCUMENTS", { parentId: openFiles._id, type }, callback => {
            if (!callback.error) setFileList(callback.response);
        });
    }, [openFiles]);

    useEffect(() => {
        fileList.forEach((_, index) => {
            if (!deleteRef.current[index]) {
                deleteRef.current[index] = createRef();
            }
        });
    }, [fileList]);

    const reloadFiles = () => {
        let type = openFiles.moderator ? "Topic" : "Subtopic";
        socketRef.current.emit("CONFERENCE_GET_TOPIC_ATTACHED_DOCUMENTS", { parentId: openFiles._id, type }, callback => {
            if (!callback.error) setFileList(callback.response);
        });
    };

    const handleDeleteFile = () => {
        console.log(openFiles._id, openFiles.moderator, 'fileId', fileIdRef.current  )
        console.log('delete')
        socketRef.current.emit("CONFERENCE_DELETE_DOCUMENT", {
            parentId: openFiles._id,
            refType: openFiles.moderator ? "Topic" : "Subtopic",
            conferenceFileId: fileIdRef.current,
            conferenceId: currentConference
        }, () => {
            // setMoreClicked({});
            setDeleteClicked(false);
            reloadFiles();
        });
    };

    const handleDelete = (fileId, index) => {
        console.log('file',fileId

        )

        fileIdRef.current = fileId;
        fileIndexRef.current = index;
        console.log(deleteRef.current[fileIndexRef.current]?.current)
        if (!deleteRef.current[fileIndexRef.current]?.current) return;
        const rect = deleteRef.current[fileIndexRef.current].current.getBoundingClientRect();
        setDeleteClicked({
            shown: true,
            funct: handleDeleteFile,
            name: "File",
            x: rect.x,
            y: rect.y,
            onClose: () => setDeleteClicked(),
        });
    };

    const handleClick = (fileId, index) => {
        fileIdRef.current = fileId;
        fileIndexRef.current = index;

        if (!deleteRef.current[index]?.current) return;

        if (moreClicked?.shown && fileId === moreClicked.id) {
            setMoreClicked({});
        } else {
            if (!moreMenuTimer) {
                const rect = deleteRef.current[fileIndexRef.current].current.getBoundingClientRect();
                setMoreClicked({
                    shown: true,
                    id: fileId,
                    clickRemove: handleDelete,
                    x: rect.x,
                    y: rect.y - 428,
                });
            }
        }
    };

    return (
        <>
            <DarkButtonComponent style={{ width: '98%', position: 'absolute', top: 92, marginBottom: 40, zIndex: GetMaxZIndex() + 1 }} onClick={() => setConferenceAddFiles(true)}>
                <FontAwesomeIcon className={styles.iconPlus} icon={byPrefixAndName.fas['plus']} /> Add files
            </DarkButtonComponent>
            <DetailsCardComponent title="Files" height={appHeight - 83} isList={fileList.length === 0 ? false : true} isBlank={fileList.length === 0 ? true : false}>
                <div style={{ width: "100%" }}>
                    <div className={styles.openFiles} style={{ justifyContent: fileList.length === 0 ? "center" : ""}}>
                        <div className={styles.noFiles} style={{ justifyContent: fileList.length === 0 ? "center" : "", alignItems: fileList.length === 0 ? "center" : "" }}>
                            {fileList.length === 0 ? (
                                <>
                                    <img src={imgAdd} alt="icon add" className={styles.imgAdd} onClick={() => setConferenceAddFiles(true)} />
                                    <div className={styles.description}>Add files to topic</div>
                                    {openFiles.name}
                                </>
                            ) : (
                                fileList.map((file, index) => (
                                    <div key={index} className={styles.fileCard}>
                                        <FontAwesomeIcon
                                            ref={deleteRef.current[index]}
                                            className={styles.iconButton}
                                            style={{ right: 10 }}
                                            icon={byPrefixAndName.fal["trash"]}
                                            onClick={(e)=>{handleDelete(file._id, index)}}
                                        />
                                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas[icon(file.docsFile.type)]} />
                                        <div className={styles.fileDetails}>
                                            {file.docsFile.name}
                                            <div className={styles.fileDescription}>
                                                Added by:
                                                <div className={styles.fileAddedBy}>{file.addedBy?.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </DetailsCardComponent>
        </>
    );
};

export default FilesDetailsConferenceDetailsComponent;
