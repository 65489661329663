import { useRef, useContext, useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import { GlobalContext } from "../../contexts/globalContext";

import styles from './PL_HeaderComponent.module.css'

import InputTextComponent from '../keyboard/InputTextComponent';
import StatusButtonComponent from './StatusButtonComponent';

import LightButtonComponent from '../commons/LightButtonComponent';
import CustomDropdownComponent from '../commons/CustomDropdownComponent';

import { sortProjects } from '../../utils/UtilitaryFunctions';

import down from '../../graphics/header/sort-down.svg'
import up from '../../graphics/header/sort-up.svg'
import KeyboardComponent from '../keyboard/KeyboardComponent';


const PL_HeaderComponent = ({ currentProjectList, setCurrentProjectList, setSeeNotification, seeNotification}) => {

    const inputRef = useRef()
    const keyboardRef = useRef()
    const projectsRef = useRef();
    const currentProjectsRef = useRef();
    const currentUserRef = useRef();
    const statusProjectListRef = useRef()

    const [isOnFocus, setIsOnFocus] = useState(false)
    const [arrow, setArrow] = useState(true)
    const [openStatus, setOpenStatus] = useState(false)

    const [currentChoice, setCurrentChoice] = useState({ value: "1", text: "Alphabetical order" })

    const { appWidth, appHeight, setCreateNewProject, upcomingConferences, statusProjectList, setStatusProjectList, language, currentUser, projectsList, socketRef, 
        setProjectsList, setSelectedProject } = useContext(GlobalContext);


    useEffect(() => {
        if (currentUser) {
            currentUserRef.current = currentUser;
        }
    }, [currentUser])

    useEffect(() => {
        if (statusProjectList) {
            statusProjectListRef.current = statusProjectList;
        }
    }, [statusProjectList])


    useEffect(() => {
        handleProjectListChanges(projectsList)
        if (projectsList?.length > 0) {
            projectsRef.current = projectsList;
        }
    }, [projectsList])

    useEffect(() => {
        if (currentProjectList?.length > 0) {
            currentProjectsRef.current = currentProjectList;
        }
    }, [currentProjectList])

    const handlestatusProjectList = (status) => {
        setStatusProjectList(status)
        socketRef.current.emit('GET_ALL_PROJECTS', currentUser._id, null, status, (projects) => {
            setProjectsList(projects)
        });
        onTextChanged()
    }

    function onTextChanged() {
        if (inputRef.current.value) {
            let aux_projects = projectsRef.current;
            let result = aux_projects.filter(el => el.name.toLowerCase().includes(inputRef.current.value.toLowerCase()));
            handleProjectListChanges(result)
        } else {
            handleProjectListChanges(projectsRef.current)
        }
    }

    function handleProjectListChanges(list) {
        if (list) {
            let choice = arrow ? currentChoice.value : parseInt(currentChoice.value) + 1
            if (inputRef.current.value) {
                list = list.filter(el => el.name.toLowerCase().includes(inputRef.current.value.toLowerCase()));
            }
            list = sortProjects(choice.toString(), list, upcomingConferences)
            setCurrentProjectList(list)
        }
    }


    function addProject() {
        setCreateNewProject(true)
    }

    const dropdownOptions = [
        { value: "1", text: "Alphabetical order" },
        // { value: "2", text: "Reverse alphabetical order" },
        { value: "3", text: "Upcoming conference" },
        // { value: "4", text: "Reverse chronological order (Upcoming conference)" },
        { value: "5", text: "Project creation date" },
        // { value: "6", text: "Reverse chronological order (Date created)" },
    ]



    useEffect(() => {
        let choice = arrow ? currentChoice.value : parseInt(currentChoice.value) + 1
        setCurrentProjectList([...sortProjects(choice.toString(), currentProjectList, upcomingConferences)])
    }, [currentChoice, arrow])

    return (

        <div className={styles.component} >
            {isOnFocus && <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 2 / 3} width="2140px" 
            height="610px" s="1"/>}

            <div className={styles.projects_number}>
                {language.projects_list_header.Projects} ({projectsList?.length})
            </div>

            <div style={{width: 950}}></div>

            <StatusButtonComponent text={language.projects_list_header.Active} buttonStatus={true} onClick={() => handlestatusProjectList(true)} />

            {(currentUser?.function?.code === "ADMN" || currentUser?.role?.toLowerCase() === "administrator") && <>
                <StatusButtonComponent text={language.projects_list_header.Inactive} buttonStatus={false} onClick={() => handlestatusProjectList(false)} />
                <StatusButtonComponent text={language.projects_list_header.All} buttonStatus={null} onClick={() => handlestatusProjectList(null)} />
            </>}

            <div className={styles.notifications} onClick={() => setSeeNotification(!seeNotification)} 
            style={{background: seeNotification ? '#060020 0% 0% no-repeat padding-box' : " transparent 0% 0% no-repeat padding-box"}}>
                <FontAwesomeIcon icon={byPrefixAndName.fal['calendar-days']} style={{color: seeNotification ? "#33FFC8" : ""}}/>
            </div>

            <CustomDropdownComponent options={dropdownOptions} forConf={true} currentChoice={currentChoice} setCurrentChoice={setCurrentChoice} setOpenStatus={setOpenStatus}
                style={{position: 'relative', marginTop: 45, width: 540, marginLeft: 60}}/>
            <div className={styles.backgroundOrderSortIcon} style={{marginLeft: 2620}}><img className={styles.orderSortIcon} src={arrow ? down : up} onClick={() => setArrow(!arrow)}/></div>
            


            <InputTextComponent ref={inputRef} keyboardRef={keyboardRef} value="" setIsOnFocus={setIsOnFocus} placeHolder={language.projects_list_header.Search} search="true" 
                styleType='SRCH'
                onTextChanged={() => { onTextChanged(statusProjectListRef.current) }}
            />
            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['magnifying-glass']} style={{ color: isOnFocus ? "#33FFC8" : "" }} />
            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['close']} onClick={() => {inputRef.current.value = ""; onTextChanged(statusProjectListRef.current)}}
            style={{marginLeft: 1143, fontSize: 40, marginTop: 63, opacity: isOnFocus ? 1 : 0}}/>

            {(currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") && <LightButtonComponent onClick={() => addProject()}
                style={{
                    width: 518,
                    height: 62,
                    marginTop: 47,
                    font: "normal normal 600 40px/62px Bio Sans",
                    position: "absolute",
                    right: "700px",
                }}>
                <FontAwesomeIcon className={styles.iconPlus} icon={byPrefixAndName.fas['plus']} />
                {language.projects_list_header['Add new project']}
            </LightButtonComponent>}
        </div>


    );

}

export default PL_HeaderComponent;