import { useContext, useEffect, useRef, useState } from 'react';
import styles from './PD_FileComponent.module.css';
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";

import MoreButtonComponent from '../../commons/MoreButtonComponent';
import DeleteNotificationComponent from '../../popup/common/DeleteNotificationComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

const PD_FileComponent = ({ file }) => {
    const deleteRef = useRef();

    const { selectedRefTypeForFiles, selectedTopicIdRef, socketRef, selectedProject } = useContext(GlobalContext);
    const { moreClicked, setMoreClicked, setDeleteClicked, moreMenuTimer } = useContext(ProjectDetailsContext);

    const handleDeleteFile = () => {
        socketRef.current.emit(
            "PROJECT_DELETE_DOCUMENT",
            {
                parentId: selectedTopicIdRef.current,
                refType: selectedRefTypeForFiles || "Topic",
                projectFileId: file._id,
                projectId: selectedProject._id
            },
            () => {
                setMoreClicked({});
                setDeleteClicked(false);
            }
        );
    };

    const icon = (() => {
        if (file.docsFile.type.includes("image")) {
            return "file-image";
        } else if (file.docsFile.type.includes("video")) {
            return "file-video";
        } else if (file.docsFile.type.includes("pdf")) {
            return "file-pdf";
        } else if (file.docsFile.type.includes("word")) {
            return "file-word";
        } else if (file.docsFile.type.includes("excel")) {
            return "file-excel";
        } else {
            return "file-lines";
        }
    })();

    const handleDelete = (e) => {
        // console.log(deleteRef.current[fileIndexRef.current]?.current)
        e.stopPropagation();
        if (!deleteRef.current) return;
        const rect = deleteRef.current.getBoundingClientRect();
        setDeleteClicked({
            shown: true,
            funct: handleDeleteFile,
            name: "File",
            x: rect.x,
            y: rect.y,
            onClose: () => setDeleteClicked(),
        });
    };

    // function handleClick() {
    //     if (moreClicked.shown && file._id === moreClicked.id) {
    //         setMoreClicked({});
    //     } else {
    //         if (!moreMenuTimer) {
    //             const rect = moreButtonRef.current.getBoundingClientRect();
    //             setMoreClicked({
    //                 ...moreClicked,
    //                 shown: true,
    //                 id: file._id,
    //                 clickRemove: handleDelete,
    //                 x: rect.x,
    //                 y: rect.y
    //             });
    //         }
    //     }
    // }

    // const handleDelete = () => {
    //     const rect = moreButtonRef.current.getBoundingClientRect();
    //     setDeleteClicked({
    //         shown: true,
    //         funct: handleDeleteFile,
    //         name: "File",
    //         x: rect.x,
    //         y: rect.y,
    //         onClose: () => setDeleteClicked(),
    //     });
    // };

    const createImage = () => {
        return process.env.REACT_APP_CMS_URL + file.docsFile.path
    };
    return (
        <div key={file._id} className={styles.element}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className={styles.info}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas[icon]} />

                    <div className={styles.participantDetails}>
                        <div className={styles.fileName}>{file.docsFile.name}</div>
                        <div className={styles.participantDescription}>
                            Added by:
                            <div className={styles.participantRole}>{file.addedBy?.name}</div>
                        </div>
                    </div>
                </div>
                {/* <MoreButtonComponent ref={moreButtonRef} clicked={moreClicked} setClicked={handleClick} id={file._id} /> */}
            </div>
            {file.docsFile.type.includes("image") && <img className={styles.image} alt='image' src={createImage()} />}
            <FontAwesomeIcon
                    ref={deleteRef}
                    className={styles.iconButton}
                    style={{ right: 50, top: '20.5%' }}
                    icon={byPrefixAndName.fal["trash"]}
                    onClick={(e) => { handleDelete(e) }}
                />
        </div>
    );
};

export default PD_FileComponent;
