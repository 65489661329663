import { useRef, useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { AddConferenceContext } from "../../../contexts/addConferenceContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './TopicsDetailsConferenceComponents.module.css';

import DetailsCardComponent from './DetailsCardComponent';
import InitialsCircleComponent from '../../commons/InitialsCircleComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent';

import AddTopicIcon from '../../../graphics/Popups/Icon Conferinta Add.png';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';

const TopicsDetailsConferenceComponents = () => {
    const [extended, setExtended] = useState(null);
    const [indexSelected, setIndexSelected] = useState(null);
    const isDeleteRef = useRef(null);

    const deleteRef = useRef()
    const subdeleteRef = useRef()

    const { socketRef, currentUser } = useContext(GlobalContext);
    const { editConference, setEditConference } = useContext(ProjectDetailsContext);
    const {
        addNewTopic,
        selectedTopic,
        selectedModerator,
        setAddModerator,
        setAddNewTopic,
        setSelectedTopic,
        setOpenFiles,
        openFiles,
        setDeleteClicked
    } = useContext(AddConferenceContext);

    useEffect(() => {
        if (selectedModerator && selectedTopic && !addNewTopic) {
            socketRef.current.emit('CONFERENCE_UPDATE_TOPIC', {
                conferenceId: editConference._id,
                topicId: selectedTopic,
                newData: {
                    moderator: selectedModerator._id,
                },
            }, callback => {
                if (!callback.error) {
                    setEditConference((prev) => ({
                        ...prev,
                        moderators: callback.response.moderators,
                        topics: prev.topics.map((topic) =>
                            topic._id === selectedTopic._id ? callback.response.updatedTopic : topic
                        ),
                    }));
                }
            });
        }
    }, [selectedModerator]);

    const handleClickExtention = (id, index) => {
        // console.log(extended)
        setExtended((prev) => (prev === id ? null : id));
        setIndexSelected(index);
    };

    const handleClickOpenFiles = (e, topic) => {
        e.stopPropagation();
        setOpenFiles(topic)
    };

    const handleEditTopic = (e, topic) => {
        e.stopPropagation();
        setAddNewTopic(true);
        setSelectedTopic(topic);
    };

    const handleUpdateModerator = (e, topic) => {
        if (currentUser?.role.toLowerCase() === "administrator") {
            e.stopPropagation();
            setAddModerator(true);
            setSelectedTopic(topic);
        }
    };

    const handleDeleteTopic = (e, type, topic) => {
        e.stopPropagation();
        isDeleteRef.current = topic;
        var rect;
        if (type === 'topic') {
            console.log(deleteRef)
            rect = deleteRef.current.getBoundingClientRect();
        } else {
            rect = subdeleteRef.current.getBoundingClientRect();
        }
        setDeleteClicked({
            shown: true,
            funct: confirmDelete,
            name: type,
            x: rect.x,
            y: rect.y,
            onClose: () => setDeleteClicked(),
        });
    };

    const confirmDelete = () => {
        const topicToDelete = isDeleteRef.current;

        if (!topicToDelete) {
            console.error("No item to delete. isDeleteRef is null.");
            return;
        }

        console.log("Confirming delete for:", topicToDelete);

        socketRef.current.emit(
            "CONFERENCE_DELETE_TOPIC",
            topicToDelete,
            editConference._id,
            (callback) => {
                if (!callback.error) {
                    setEditConference((prev) => ({
                        ...prev,
                        topics: callback.response,
                    }));
                    setSelectedTopic();
                    setOpenFiles();
                    setDeleteClicked()
                }
            }
        );

        isDeleteRef.current = null;
    };


    return (
        <>
            {currentUser?.role.toLowerCase() === "administrator" && (
                <DarkButtonComponent style={{ width: '99%', position: 'absolute', top: 92, marginBottom: 40, zIndex: GetMaxZIndex() + 1 }} onClick={() => { setAddNewTopic(true); setSelectedTopic(); }}>Add Topic</DarkButtonComponent>)}
            {editConference.topics.length > 0 ? (
                <DetailsCardComponent title="Topics" indexSelected={indexSelected}>
                    {editConference.topics.map((topic, index) => (
                        <div key={topic._id} style={{ width: "100%" }}
                        onClick={(e) => { 
                            handleClickExtention(topic._id, index);
                            handleClickOpenFiles(e, topic);
                        }}>
                            <div className={styles.notExtended}>
                                <div className={styles.index}>
                                    {index < 9 ? `0${index + 1}` : index + 1}.
                                </div>
                                <div style={{ color: openFiles?._id === topic._id ? "#33FFC8" : "",}} onClick={(e) => {
                                    if(currentUser?.role.toLowerCase() === "administrator" || currentUser._id === topic.moderator?._id)   
                                        handleClickExtention(topic._id, index);
                                    handleClickOpenFiles(e, topic);
                                }}>
                                    {topic.name}
                                </div>
                                {extended === topic._id && (
                                    <>
                                        <FontAwesomeIcon className={styles.iconButton} style={{ right: currentUser?.role.toLowerCase() === "administrator" ? 140: 60}}
                                            icon={byPrefixAndName.fal["pen"]} onClick={(e) => handleEditTopic(e, topic)}
                                        />
                                        {currentUser?.role.toLowerCase() === "administrator" && (<FontAwesomeIcon ref={deleteRef} className={styles.iconButton} style={{ right: 60 }}
                                            icon={byPrefixAndName.fal["trash"]} onClick={(e) => handleDeleteTopic(e, 'topic', topic._id)}/>
                                        )}
                                    </>
                                )}
                                {(currentUser?.role.toLowerCase() === "administrator" || currentUser._id === topic.moderator?._id) && ( <FontAwesomeIcon className={styles.iconButton}
                                    style={{color: openFiles?._id === topic._id? "#33FFC8": ""}} icon={byPrefixAndName.fas["chevron-right"]} 
                                    onClick={(e) => handleClickOpenFiles(e, topic)}/>
                                )}
                                <div className={styles.details} style={{ color: topic.moderator?._id === currentUser._id ? "#FFFFFF" : "" }} >
                                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal["bookmark"]} />
                                    {topic.subtopics?.length}
                                    <div className={styles.moderatorInfo} style={{color: topic.moderator?._id === currentUser._id ? "#33FFC8" : ""}}>
                                        <FontAwesomeIcon className={styles.icon} icon={ byPrefixAndName.fal["microphone-stand"]}/>
                                        {topic.moderator?.name}
                                    </div>
                                </div>
                            </div>
                            {extended === topic._id &&
                                (currentUser?.role.toLowerCase() ===
                                    "administrator" ||
                                    currentUser._id === topic.moderator?._id) && (
                                    <div className={styles.extended} style={{borderTop: topic.subtopics.length > 0 ? '1px solid #5E547C' : 'none', marginTop: topic.subtopics.length > 0 ? 40 : 20}}>
                                        {topic.subtopics.length > 0 && <div className={styles.subtopics}>
                                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal["bookmark"]}
                                            />
                                            Subtopics(Optional)
                                            {topic.subtopics.length > 0 && topic.subtopics.map((subtopic) => (<div key={subtopic._id} className={styles.subtopic}
                                            onClick={(e) => handleClickOpenFiles(e,subtopic) }>
                                                {(currentUser?.role.toLowerCase() === "administrator" || currentUser._id === topic.moderator?._id) && (<FontAwesomeIcon
                                                    className={styles.iconOpenFilesSubtopic} style={{ color: openFiles?._id === subtopic._id ? "#33FFC8" : ""}}
                                                    icon={ byPrefixAndName.fas["chevron-right"]}/>
                                                )}
                                                <div style={{ color:openFiles?._id === subtopic._id ? "#33FFC8" : ""}}>
                                                    {subtopic.name}
                                                </div>
                                            </div>
                                        )
                                    )}
                                        </div>}
                                        <div className={styles.moderator} onClick={(e) => handleUpdateModerator(e, topic)}>
                                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['microphone-stand']} />Moderator
                                            <div key={topic.moderator._id} className={styles.participantCard} >
                                                <InitialsCircleComponent name={topic.moderator.name} style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10 }} />
                                                <div className={styles.participantDetails}>
                                                    {topic.moderator.role}
                                                    <div className={styles.participantName}>{topic.moderator.name}</div>
                                                    {topic.moderator.function?.name} {topic.moderator.function ? "|" : ""} {topic.moderator.department?.name}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )}
                        </div>
                    ))}
                </DetailsCardComponent>
            ) : (
                <DetailsCardComponent title="Topics">

                    {currentUser?.role.toLowerCase() === "administrator" ? (
                        <>
                            <div style={{ width: 2015, height: 1261, marginTop: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className={styles.centered}>
                                    <img src={AddTopicIcon} alt="icon add" className={styles.imgAdd} onClick={() => setAddNewTopic(true)} />
                                    <label className={styles.labelCreate}>Create new topic</label>
                                    <label className={styles.labelTextBold}>
                                        Topics and moderators are mandatory for every conference. <br />
                                        Subtopics are optional and help organize content more effectively.
                                        <br />Start by clicking the Add Topic button to begin creating topics.
                                    </label>
                                </div>
                            </div>
                        </>) : (
                        <div style={{ width: "100%" }}> No topics added yet.</div>
                    )}
                </DetailsCardComponent>

            )}
            
        </>
    );

};

export default TopicsDetailsConferenceComponents;
