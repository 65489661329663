import { useEffect, useContext, useState, useRef } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import TopicDetailsComponent from "./TopicDetailsComponent"

const TopicComponent = ({ topic, index, setSelectedTopic, selectedTopic }) => {

    const { currentTopic, currentParticipant, conferenceSocketRef, currentConference } = useContext(GlobalContext);
    const [topicNumber, setTopicNumber] = useState("01");

    const [opened, setOpened] = useState(false);

    const selectedRef = useRef();



    useEffect(() => {
        if (index < 10) {
            setTopicNumber("0" + index);
        } else {
            setTopicNumber("" + index);
        }
        selectedRef.current = false;
    }, [])

    const GoToNextTopic = () => {
        conferenceSocketRef.current.emit("GO_TO_NEXT_TOPIC", currentConference._id);
    }

    const CheckTopic = (e) => {
        e.stopPropagation();
        var elements = document.getElementsByName("topics_radio");

        elements.forEach(element => {
            if (element != e.target) {
                element.checked = false;
            }

        });

        console.log(topic);
        if (e.target.checked) {
            conferenceSocketRef.current.emit("GO_TO_TOPIC", currentConference._id, topic._id);
        } else {
            conferenceSocketRef.current.emit("GO_TO_TOPIC", currentConference._id, null);
        }


    }

    // const ChangeRadioState = (e) => {
    //     selectedRef.current = e.target.checked
    //     console.log(e.target.checked);
    //     // if (selectedRef.current) {
    //     //     e.target.checked = false;
    //     // } else {
    //     //     e.target.checked = true;
    //     // }
    //     // selectedRef.current = !selectedRef.current;
    // }



    return (
        <div style={{ display: "flex", flexDirection: "column", width: "auto", height: "auto", marginBottom: "10px", marginTop: "5px", borderRadius: "10px", overflow: "hidden" }}>
            <div onClick={() => setSelectedTopic((topic._id == selectedTopic) ? null : topic._id)} style={{ borderRadius: (topic.moderator._id == currentParticipant ? "10px" : "10px 10px 0px 0px"), display: "flex", padding: "0px", margin: "0px 0px", color: "white", width: "900px", height: "100%", backgroundColor: (topic._id == currentTopic?._id ? "#292148" : "#161032"), fontSize: "30px", overflow: "hidden" }}>
                {currentTopic?._id == topic._id &&
                    <>




                        <div style={{ height: "auto", padding: "0px 20px", color: "#33FFC8", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#423467", font: "normal normal bold 74px/126px 'Helvetica Condensed'" }}>
                            {currentConference.type == "Presentation" && <input type="checkbox" name="topics_radio" checked style={{ width: "10px", marginLeft: "0px", marginRight: "10px" }} onClick={(e) => CheckTopic(e)} />}
                            {topicNumber}
                        </div>
                        <div style={{ width: "100%", marginLeft: "20px" }}>
                            <div style={{ font: "normal normal 600 70px/74px 'Bio Sans'", color: "#33FFC8", padding: "40px 0px" }}>{topic.name}</div>
                            <div style={{ font: "normal normal normal 32px/54px 'Bio Sans'", color: "#EEEEEE", position: "relative", marginTop: "-25px" }}>
                                <i className="fa-thin fa-microphone-stand" style={{ font: "normal normal 300 28px/35px", margin: "0px 20px 40px 0px" }}></i>
                                {topic?.moderator.name}
                                {currentTopic?.moderator._id == currentParticipant._id && currentConference.type != "Presentation" && <div style={{ position: "absolute", right: "20px", bottom: "20px", background: "transparent linear-gradient(91deg, #77EDDC 0%, #DBFD95 97%, #DEFE93 100%) 0% 0% no-repeat padding-box", color: "black", padding: "10px 20px", font: " normal normal 600 24px/33px Bio Sans", borderRadius: "8px" }} onClick={() => GoToNextTopic()}>NEXT TOPIC</div>}
                            </div>


                        </div>
                    </>
                }
                {currentTopic?._id != topic._id &&
                    <>
                        {currentConference.type == "Presentation" && <input type="checkbox" name="topics_radio" style={{ width: "40px", marginLeft: "20px" }} onClick={(e) => CheckTopic(e)} />}
                        <div style={{ fontSize: "74px", fontWeight: "bold", margin: "0px 20px", color: "#726FA8", display: "flex", justifyContent: "center", alignItems: "center", font: "normal normal bold 74px/126px 'Helvetica Condensed'" }}>{topicNumber}</div>
                        <div style={{ width: "100%", marginLeft: "20px" }}>
                            <div style={{ font: "normal normal 600 50px/44px Bio Sans", color: "#726FA8", margin: "40px 20px 0px 0px" }}>{topic.name}</div>
                            <div style={{ font: "normal normal normal 32px/54px Bio Sans'", color: "#726FA8" }}>

                                <i className="fa-thin fa-microphone-stand" style={{ font: "normal normal 300 28px/35px", margin: "10px 20px 40px 0px" }}></i>
                                {topic?.moderator.name}
                            </div>
                        </div>
                    </>
                }


            </div>

            {topic.moderator._id == currentParticipant._id && (currentTopic?._id == topic._id || selectedTopic == topic._id) &&
                <TopicDetailsComponent key={topic._id} topic={topic}></TopicDetailsComponent>
            }
        </div>
    );
}

export default TopicComponent;