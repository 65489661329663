import { useEffect, useRef, useContext } from 'react';
import NoVideo from '../../graphics/no-video.png';
import { GlobalContext } from "../../contexts/globalContext"


const VideoStreamComponent = (props) => {
    const { currentConference, conferenceSocketRef } = useContext(GlobalContext);
    const videoRef = useRef(null);

    useEffect(() => {
        console.log("currentConference", currentConference);
        //console.log("creare video", props.videoStream.producerId);
        videoRef.current.srcObject = props.videoStream.stream;

        conferenceSocketRef.current.on('PLAY_VIDEOS', function () {
            console.log("PLAY_VIDEOS");
            videoRef.current.play();            
        });

        conferenceSocketRef.current.on('PAUSE_VIDEOS', function () {
            console.log("PAUSE_VIDEOS");
            videoRef.current.pause();            
            videoRef.current.load();
        });
    }, [])

    return (
        <div style={{ position: "relative", marginTop:"10px" }}>
            <video ref={videoRef} style={{  }} width="auto" height={props.height} poster={NoVideo} autoPlay={currentConference.type=="Presentation"?false:true} ></video>
            
            <div style={{ backgroundColor:"#00000077", width:"100%", position: "absolute", left:"0px", bottom:"0px", fontSize:"30px", color:"white"}}>{props.videoStream.participant.name}</div>
        </div>
    );
}

export default VideoStreamComponent;