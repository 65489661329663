import { useRef, useContext, useState, useEffect } from 'react';
import styles from './ImageFileComponent.module.css'
import { GlobalContext } from "../contexts/globalContext"
//import { MultitouchComponent } from 'use-gesture-pack';
import CustomMultitouchComponent from "./CustomMultitouchComponent"
import ShareIcon from "../graphics/conference/icon-share-btn.svg"


const ImageFileComponent = ({ file, shared }) => {

    const ref = useRef(null);
    const imgRef = useRef(null);
    const mtRef = useRef(null);
    const { videoStreams, setSharedFiles, setOpenedFiles, appWidth, appHeight, currentParticipant, currentTopic, sharedFiles, conferenceSocketRef, currentConference } = useContext(GlobalContext);
    const [isVisible, setIsVisible] = useState(false);

    const mustBeDeletedRef = useRef();
    const updateRequiredRef = useRef();
    const sharedFilesRef = useRef();
    const [showParticipants, setShowParticipants] = useState(false);

    const startingHeight = 600

    const options = {
        appWidth,
        appHeight,
        maxScale: appHeight / startingHeight,
        minScale: 0.5
    }

    const calculateWidth = () => {
        if (imgRef.current) {
            const containerHeight = startingHeight;
            const image = imgRef.current;
            const imageAspectRatio = image.height / image.width;
            const containerWidth = containerHeight / imageAspectRatio;

            return containerWidth;
        }

        return 0;
    };

    const containerStyle = {
        width: `${isVisible ? calculateWidth() : 60}px`,
        height: `${isVisible ? startingHeight : 75}px`,
        // opacity: `${isVisible ? 1 : 0}`,
        overflow: 'hidden',
        transition: `width 0.5s ease, height 0.5s ease, transform 0.5s ease`,
        borderRadius: '0px',
        padding: '0px',
        transformOrigin: 'center center',
        transform: `translate(-50%, -50%) matrix(1, 0, 0, 1, 0,0)`,
        backdropFilter: `${isVisible ? 'blur(10px)' : ''}`,
        backgroundColor: `${isVisible ? 'rgba(0, 0, 0, 0.3)' : 'transparent'}`
    };

    useEffect(() => {
        mustBeDeletedRef.current = false;
        updateRequiredRef.current = true;
    }, [])

    const onMinScaleReached = () => {
        mustBeDeletedRef.current = true;

        setOpenedFiles(files => files.filter(a => a.uid !== file.uid));

        if (shared) {
            var filtrat = sharedFilesRef.current.filter(a => a.uid !== file.uid);
            setSharedFiles(filtrat);
            currentConference.shared_data = JSON.stringify(filtrat);
            conferenceSocketRef.current.emit("UPDATE_SHARE", currentConference._id, filtrat);
        }
    }

    const onGestureEnded = () => {
        if (shared) {
            if (!mustBeDeletedRef.current) {
                ShareDocument();
            }
        } else {
            SaveOpenedFilesState();
        }
    }

    const SaveOpenedFilesState = () => {
        updateRequiredRef.current = false;
        file.transform = mtRef.current?.style.transform;

        setOpenedFiles(objs =>
            objs.map(item =>
                item.uid === file.uid ? file : item
            )
        );
    }

    const ShareDocument = () => {
        updateRequiredRef.current = false;
        sharedFilesRef.current = sharedFilesRef.current.filter(a => a.uid !== file.uid);

        file.transform = mtRef.current?.style.transform;

        if (!file.sharedBy) {
            file.sharedBy = currentParticipant._id;
            file.sharedByName = currentParticipant.name;
        }

        file.moderatedBy = currentParticipant._id;
        file.moderatedByName = currentParticipant.name;


        sharedFilesRef.current = [...sharedFilesRef.current, file];

        setOpenedFiles(devices => devices.filter(a => a.uid !== file.uid));

        currentConference.shared_data = JSON.stringify(sharedFilesRef.current);
        conferenceSocketRef.current.emit("UPDATE_SHARE", currentConference._id, sharedFilesRef.current);
    }

    useEffect(() => {
        if (shared) {
            if (currentParticipant._id == file.moderatedBy || currentParticipant._id == file.sharedBy) {
                mtRef.current.style.msTouchAction = "auto";
                mtRef.current.style.pointerEvents = "auto";
                mtRef.current.style.touchAction = "auto";

                ref.current.style.msTouchAction = "auto";
                ref.current.style.pointerEvents = "auto";
                ref.current.style.touchAction = "auto";

            } else {
                mtRef.current.style.msTouchAction = "none";
                mtRef.current.style.pointerEvents = "none";
                mtRef.current.style.touchAction = "none";

                ref.current.style.msTouchAction = "none";
                ref.current.style.pointerEvents = "none";
                ref.current.style.touchAction = "none";
            }
        }


        if (updateRequiredRef.current) {
            if (file.transform) {
                mtRef.current.style.transform = file.transform;
            }

        } else {
            updateRequiredRef.current = true;
        }
    }, [file])

    useEffect(() => {
        sharedFilesRef.current = sharedFiles;
    }, [sharedFiles])

    const ChangeModerator = (moderatorId, moderatorName) => {
        updateRequiredRef.current = false;
        sharedFilesRef.current = sharedFilesRef.current.filter(a => a.uid !== file.uid);


        file.moderatedBy = moderatorId;
        file.moderatedByName = moderatorName;


        sharedFilesRef.current = [...sharedFilesRef.current, file];

        setOpenedFiles(devices => devices.filter(a => a.uid !== file.uid));

        currentConference.shared_data = JSON.stringify(sharedFilesRef.current);
        conferenceSocketRef.current.emit("UPDATE_SHARE", currentConference._id, sharedFilesRef.current);
        setShowParticipants(false);

    }

    return (
        <CustomMultitouchComponent ref={mtRef} dragGesture={false} x={file.x + 75} y={file.y + 40} onMinScaleReached={onMinScaleReached} onGestureEnded={onGestureEnded} options={options}>
            <div ref={ref} className={`${styles.touch_component}  ${isVisible ? 'visible' : ''}`} style={containerStyle}>
                {/* <Lottie
                    loop
                    animationData={LoadingJson}
                    play
                    style={{ position:"absolute", left:"-5px", top:"-5px", width: 85, height: 100, opacity: `${isVisible ? 0 : 1}`  }}
                />
                <img style={{ position:"absolute", width: 60, height: 75, opacity: `${isVisible ? 0 : 1}`}} alt="icon" draggable="false" src={Icon} /> */}
                <img alt="imagine" ref={imgRef} style={{ borderRadius: "0px", width: "100%" }} onLoad={() => { setIsVisible(true) }} draggable="false" src={process.env.REACT_APP_CMS_URL + file.path} />
                {/* <img alt="imagine" ref={imgRef} style={{ borderRadius: "7.5px", width:"100%" }} onLoad={()=>{setIsVisible(true)}} draggable="false" src={"https://cms.nextfusion.ro/" + file.path} /> */}

                {/* Nou */}
                {shared && <div style={{ position: "absolute", right: "20px", top: "20px", backgroundColor: "#000000", color: "white", padding: "5px" }}>Moderator: {file.moderatedByName}</div>}

                {!shared && (!currentTopic || currentParticipant._id == currentTopic?.moderator._id) &&
                    <img src={ShareIcon} style={{ position: "absolute", left: "10px", top: "10px", width: "120px" }} onClick={() => ShareDocument()} />
                }

                {shared && file.sharedBy == currentParticipant._id &&
                    <button onClick={() => setShowParticipants(true)} style={{ position: "absolute", left: "10px", bottom: "10px", width: "120px" }}>Change moderator</button>
                }
                {shared && file.sharedBy == currentParticipant._id && showParticipants &&
                    <div style={{ position: "absolute", left: "0px", top: "0px", width: "100%", height: "100%", backgroundColor: "red" }}>
                        {videoStreams.map((videoStream) => (
                            <button onClick={() => ChangeModerator(videoStream.participant._id, videoStream.participant.name)}>{videoStream.participant.name}</button>
                        ))}
                    </div>
                }

                {/* {shared && <div style={{ position: "absolute", right: "20px", top:"20px", backgroundColor:"#000000", color:"white", padding:"5px" }}>Shared by: {file.sharedByName}</div>} */}
            </div>
        </CustomMultitouchComponent>
    );

}

export default ImageFileComponent;