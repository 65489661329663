import { useRef, useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../contexts/globalContext";

import { ProjectDetailsContext } from "../contexts/projectDetailsContext";


import styles from './ProjectDetailsComponent.module.css'

import AddGuestComponent from './popup/addParticipantsParts/AddGuestsComponent';
import AddTopicsComponent from './popup/AddTopicsComponent';
import AddFilesComponent from './popup/AddFilesComponent';
import AddPartipicantsComponent from './popup/addParticipantsParts/AddParticipantsComponent';
// import AddConferenceComponent from './popup/AddConferencePopup';
import AddConferenceComponentNew from './popup/AddConferenceComponentNew';
import ConferenceListComponent from './projectDetails/conferences/PD_ConferenceListComponent';
import ParticipantListComponent from './projectDetails/participants/PD_ParticipantListComponent';
import FileListComponent from './projectDetails/files/PD_FileListComponent';
import TopicListComponent from './projectDetails/topics/PD_TopicListComponent';
import MoreMenuComponent from './commons/MoreMenuComponent';
import UserDetailsComponent from './UserDetailsComponent';
import AddMembersComponent from './popup/addParticipantsParts/AddMembersComponent';
import AddPartnersComponent from './popup/addParticipantsParts/AddPartnersComponent';
import AddVisitorsComponent from './popup/addParticipantsParts/AddVisitorsComponent';
import HeaderComponent from './commons/HeaderComponent';
import NotificationComponent from './NotificationComponent';
import AddMeetingComponent from './popup/AddMeetingComponent';
import ConferenceDetailsComponent from './popup/conferenceDetails/ConferenceDetailsComponent';
import DeleteNotificationComponent from './popup/common/DeleteNotificationComponent';

import video from '../graphics/big wave fundal loop_6.mp4'

import { AddConferenceProvider } from "../contexts/addConferenceContext"
import NewConferenceAddComponent from './popup/NewConferenceAddComponent';




const ProjectDetailsComponent = () => {

    const ref = useRef()

    const { seeUserDetails, setSelectedParentForFiles, selectedProject, appHeight, appWidth, setOpenNotif, openNotif, seeNotification, setSeeNotification } = useContext(GlobalContext);
    const {
        addGuest,
        addTopic,
        addFiles,
        addParticipants,
        createConference,
        editConference,
        editTopic,
        moreClicked, setMoreClicked,
        addMembers,
        addVisistors,
        addPartners,
        createMeeting,
        deleteClicked, setDeleteClicked,
        moreMenuTimer, setMoreMenuTimer
    } = useContext(ProjectDetailsContext);

    useEffect(() => {
        if (selectedProject.topics && selectedProject.topics.length > 0) {
            setSelectedParentForFiles(selectedProject.topics[0])
        }
    }, [])

    useEffect(() => {
        if(!seeNotification){
            setTimeout(() => {
                setOpenNotif(false)
            }, 450);
            
        } else{
            setOpenNotif(true)
        }
    }, [seeNotification])

    useEffect(() => {
        if(moreMenuTimer){
            setTimeout(() => {
                setMoreMenuTimer(false)
            }, 200);
        }
    }, [moreMenuTimer]);


    return (
        <div ref={ref} className={styles.project_component} style={{ height: `${appHeight}px`, width: `${appWidth}px`}}>
            <video id="background-video" autoPlay loop playsInline muted style={{ height: `${appHeight}px`, width: `${appWidth}px`}}>
                <source src={video} type="video/mp4"/>
            </video>

            <div className={styles.topShadow} />
            <HeaderComponent setSeeNotification={setSeeNotification} seeNotification={seeNotification} />
            {openNotif && <NotificationComponent />}
            <div className={styles.lists}>
                <ConferenceListComponent />
                <ParticipantListComponent />
                <TopicListComponent />
                <FileListComponent />
            </div>
            <div className={styles.bottomShadow} />
            {seeUserDetails && <UserDetailsComponent />}
            {moreClicked?.shown == true && <MoreMenuComponent moreClicked={moreClicked} setMoreClicked={setMoreClicked} setMoreMenuTimer={setMoreMenuTimer} />}
            {deleteClicked?.shown == true && <DeleteNotificationComponent deleteClicked={deleteClicked} setDeleteClicked={setDeleteClicked} />}
            {/* <AddNewDetailsComponent /> */}

            {/* {editName && <EditNameComponent />} */}

            {addTopic && <AddTopicsComponent />}
            {addFiles && <AddFilesComponent />}
            {addParticipants && <AddPartipicantsComponent />}
            {editTopic && <AddTopicsComponent />}
            
            {addGuest && <AddGuestComponent />}
            {addMembers && <AddMembersComponent />}
            {addPartners && <AddPartnersComponent />}
            {addVisistors && <AddVisitorsComponent />}

            
            {createConference && <AddConferenceProvider><AddConferenceComponentNew /> </AddConferenceProvider>}
            {/* {createConference && <AddConferenceProvider><NewConferenceAddComponent /> </AddConferenceProvider>} */}

            {createMeeting && <AddConferenceProvider><AddMeetingComponent /> </AddConferenceProvider>}

            {editConference && <AddConferenceProvider><ConferenceDetailsComponent /> </AddConferenceProvider>}
        </div>
    );

}

export default ProjectDetailsComponent;