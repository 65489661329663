import { useEffect, useRef, useState, useContext } from 'react';

import { GlobalContext } from "../../contexts/globalContext";

const SideMenuComponent = () => {

    const { currentConference, conferenceSocketRef, currentParticipant } = useContext(GlobalContext)

    const [hour, setHour] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [dateString, setDateString] = useState("00");

    const [videoArePlaying, setVideoArePlaying] = useState(false);

    const intervalRef = useRef();

    useEffect(() => {
        const date = new Date();
        setHour(date.getHours());
        var mins = date.getMinutes();
        if (mins < 10) {
            mins = "0" + mins;
        }
        setMinutes(mins);
        var d_str = date.toLocaleDateString("en-EN", { weekday: 'short' }) + " " + date.getDate() + " " + date.toLocaleDateString("en-EN", { month: 'short' }) + " " + date.getFullYear();
        setDateString(d_str);


        intervalRef.current = setInterval(() => {
            const date = new Date();
            setHour(date.getHours());
            var mins = date.getMinutes();
            if (mins < 10) {
                mins = "0" + mins;
            }
            setMinutes(mins);
            var d_str = date.toLocaleDateString("en-EN", { weekday: 'short' }) + " " + date.getDate() + " " + date.toLocaleDateString("en-EN", { month: 'short' }) + " " + date.getFullYear();
            setDateString(d_str);
        }, 1000);

        return () => {
            clearInterval(intervalRef.current);
        }

    }, []);

    const StopConference = () => {
        conferenceSocketRef.current.emit("STOP_CONFERENCE", currentConference._id);
    }

    const PauseConference = () => {
        conferenceSocketRef.current.emit("PAUSE_CONFERENCE", currentConference._id);
    }

    const PlayVideos = () => {
        
        if (videoArePlaying) {
            conferenceSocketRef.current.emit("PAUSE_VIDEOS", currentConference._id);
        } else {
            conferenceSocketRef.current.emit("PLAY_VIDEOS", currentConference._id);
        }
        setVideoArePlaying(!videoArePlaying);
    }

    

    

    return (

        <div style={{ position: "absolute", display: "flex", flexDirection: "column", width: "125px", height: "auto", bottom: "20px", left: "20px", top: "20px", backgroundColor: "#292148", borderRadius: "10px", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>

                <div style={{ font: "normal normal 600 71px/64px 'Bio Sans'", color: "#C3C2CA" }}> {hour}</div>
                <div style={{ font: "normal normal 300 71px/64px 'Bio Sans'", color: "#C3C2CA" }}> {minutes}</div>
                <div style={{ font: "normal normal 600 28px/32px Bio Sans", color: "#726FA8", margin: "25px 10px", textAlign: "center" }}> {dateString}</div>
            </div>

            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>

                <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-messages" style={{ fontSize: "50px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", margin: "10px", marginTop: "30px" }}>CHAT</div>
                </div>

                <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-calculator" style={{ fontSize: "50px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", margin: "10px", marginTop: "30px" }}>CALCULATOR</div>
                </div>

                <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-notes" style={{ fontSize: "50px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", margin: "10px", marginTop: "30px" }}>NOTES</div>
                </div>

                <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-globe-pointer" style={{ fontSize: "50px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", margin: "10px", marginTop: "30px" }}>BROWSER</div>
                </div>

                <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="fa-thin fa-coins" style={{ fontSize: "50px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", margin: "10px", marginTop: "30px" }}>CURRENCY</div>
                </div>

                <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <i className="fa-thin fa-calendar-lines" style={{ fontSize: "50px", color: "#726FA8" }}></i>
                    <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", margin: "10px", marginTop: "30px" }}>CALENDAR</div>
                </div>

            </div>

            {currentConference?.topics[0].moderator._id == currentParticipant._id &&
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

                    {currentConference.type == "Presentation" &&
                        <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }} onClick={() => PlayVideos()}>
                            
                            {videoArePlaying &&<i className="fa-thin fa-video-slash" style={{ fontSize: "50px", color: "#726FA8" }}></i>}
                            {!videoArePlaying &&<i className="fa-thin fa-video" style={{ fontSize: "50px", color: "#726FA8" }}></i>}
                            <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8",  marginTop: "20px" }}>{videoArePlaying?"STOP":"PLAY"} VIDEOS</div>
                        </div>
                    }

                    <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }} onClick={() => PauseConference()}>
                        <i className="fa-thin fa-pause" style={{ fontSize: "50px", color: "#726FA8" }}></i>
                        <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", margin: "10px", marginTop: "20px" }}>PAUSE</div>
                    </div>

                    <div style={{ cursor: "pointer", width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={() => StopConference()}>
                        <i className="fa-thin fa-power-off" style={{ fontSize: "50px", color: "#726FA8" }}></i>

                        <div style={{ font: "normal normal 600 18px/22px Bio Sans", color: "#726FA8", margin: "10px", marginBottom: "20px", marginTop: "20px" }}>CLOSE</div>
                    </div>
                </div>
            }

        </div>
    );
}

export default SideMenuComponent;