import { useRef, useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../../../contexts/globalContext"
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { AddConferenceContext } from "../../../contexts/addConferenceContext"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './ParticipantsDetailsConferenceComponent.module.css'

import DetailsCardComponent from './DetailsCardComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent'
import InitialsCircleComponent from '../../commons/InitialsCircleComponent';




const ParticipantsDetailsConferenceComponent = () => {

    const { currentUser } = useContext(GlobalContext)
    const { editConference } = useContext(ProjectDetailsContext)
    const { setOpenParticipants, setConferenceDetailsForParticipants, setUniqueModerators, uniqueModerators } = useContext(AddConferenceContext)

    const typesOfParticipants = [ "members", "guests", "partners", "visitors"]

    useEffect(() => {
        setUniqueModerators( editConference.moderators.filter((moderator, index, self) => 
            index === self.findIndex((mod) => mod._id === moderator?._id)
        ))
    },[editConference])

    const iconParticipants = (type) => {
        switch (type) {
            case 'members':
                return "user";
            case 'guests':
                return "id-card-clip";
            case 'partners':
                return "user-tie";
            default:
                return "address-card";
        }

    }


    return (
        <DetailsCardComponent title="Participants"  isList={false} >
            <div style={{width: "100%"}}>
                <div className={styles.line}>
                    <div className={styles.infoBox} style={{width: "30%", color: "#33FFC8"}}>
                        <div className={styles.infoDescription}><FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fal['calendar']} />Total Participants</div>
                        {editConference.participantsWithDesk + editConference.participantsWithoutDesk }
                    </div>
                    <div className={styles.infoBox} style={{width: "calc(30% - 65px)", paddingLeft: 65}}>
                        <div className={styles.infoDescription}><FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fal['calendar-clock']} />In-room</div>
                        {editConference.participantsWithDesk}
                    </div>
                    <div className={styles.infoBox} style={{width: "calc(30% - 65px)", borderRight: "0px", paddingLeft: 65}}>
                        <div className={styles.infoDescription}><FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fal['calendar-clock']} />Remote</div>
                        {editConference.participantsWithoutDesk}
                    </div>
                </div>
                <div className={styles.line}>
                    <div className={styles.infoDescription} style={{position: "absolute", top: 40}} >
                        <FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fal['microphone-stand']} />Moderator
                    </div>
                    {uniqueModerators.length > 0 && uniqueModerators.map((moderator) => 
                        <div key={moderator?._id} className={styles.participantCard} >
                            <InitialsCircleComponent name={moderator?.name} style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10 }} />
                            <div className={styles.participantDetails}>
                                {moderator?.role}
                                <div className={styles.participantName}>{moderator?.name}</div>
                                {moderator?.function?.name} {moderator?.function ? "|" : ""} {moderator?.department?.name}
                            </div>
                        </div>
                    )}
                    {uniqueModerators.length === 0 && <div style={{marginTop: 90}}>
                        No moderators added yet
                    </div>}
                </div>
                <div className={styles.line} style={{flexDirection: "row"}}>
                    {typesOfParticipants.map((type, index) => 
                        <div className={styles.infoDescription} style={{fontSize: 28, paddingTop: 30, marginRight: 70}} key={index}>
                            <FontAwesomeIcon className={styles.iconSmall} icon={byPrefixAndName.fal[iconParticipants(type)]} />{type.charAt(0).toUpperCase() + type.slice(1)}:
                            <div style={{font: "normal normal bold 28px/42px", color: "#FFFFFF", marginLeft: 10}}>{editConference.roleCounts[type]}</div>
                        </div>
                    )}
                </div>
                {currentUser?.role.toLowerCase() === "administrator" && 
                    <DarkButtonComponent style={{ backgroundColor: "transparent", position: "relative", width: 337, marginLeft: 67, marginTop: 40 }} onClick={() => {
                        setOpenParticipants(true)
                        setConferenceDetailsForParticipants(editConference)
                    }}>
                        <FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fas['pen']} /> Edit
                    </DarkButtonComponent>
                }
            </div>
        </DetailsCardComponent>
    );

}

export default ParticipantsDetailsConferenceComponent;