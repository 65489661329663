import { useRef, useContext, useState, useEffect, createRef } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './ConferenceAddFilesComponent.module.css'
import InputTextComponent from '../../keyboard/InputTextComponent';
// import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import CheckboxComponent from '../common/CheckboxComponent';
import CustomDropdownComponent from '../../commons/CustomDropdownComponent'
import LeftBodyComponent from '../common/LeftBodyComponent';
import RightBodyComponent from '../common/RightBodyComponent';

import KeyboardComponent from '../../keyboard/KeyboardComponent';
import filesAdd from '../../../graphics/Popups/Icon Files Add.png'
import DarkButtonComponent from '../../commons/DarkButtonComponent';
import LightButtonComponent from '../../commons/LightButtonComponent';
import ElementTableComponent from '../common/ElementTableComponent';

import { AddConferenceContext } from '../../../contexts/addConferenceContext';

const ConferenceAddFilesComponent = () => {

    const inputRef = useRef()
    const keyboardRef = useRef()
    const inputFileRef = useRef()
    const docsRef = useRef()
    const initialRender = useRef(true)
    const currentChoiceRef = useRef("all")

    const [docs, setDocs] = useState()
    const [allDocs, setAllDocs] = useState()

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [isOnFocus, setIsOnFocus] = useState(false)
    const [fileOnFocus, setFileOnFocus] = useState(false)


    const [currentChoice, setCurrentChoice] = useState({
        value: "all",
        text: "All"
    })

    const { appHeight, appWidth, language, currentUser, socketRef  } = useContext(GlobalContext);
    const { editConference, setEditConference } = useContext(ProjectDetailsContext);
    const { openFiles, setConferenceAddFiles, setFileList} = useContext(AddConferenceContext)

    const fileRefs = useRef(filesToBeUploaded.map(() => createRef()));


    const optionsTypes = [
        {
            value: "all",
            text: "All"
        },
        {
            value: "image",
            text: "Image"
        },
        {
            value: "pdf",
            text: "PDF"
        },
        {
            value: "word",
            text: "Word"
        },
        {
            value: "ms-excel",
            text: "Excel"
        },
        {
            value: "video",
            text: "Video"
        }
    ]

    const allowedTypes = ".docx, .doc, .xlsx, .xls, .jpg, .jpeg, .png, .gif, .svg, .mp4, .avi, .webm, .mpeg, .pdf"


    useEffect(() => {
        socketRef.current.emit("CONFERENCE_GET_UNATTACHED_DOCUMENTS", { conferenceId: editConference._id, userId: currentUser._id }, (callback) => {
            if(!callback.error){
                setAllDocs(callback.response)
                setDocs(callback.response)
                docsRef.current = callback.response
            }
            
            // console.log(callback)
        })

    }, [])

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        handleSelectType(currentChoice.value)
        currentChoiceRef.current = currentChoice.value
    }, [currentChoice])

    const handleBrosweButtonClick = () => {
        inputFileRef.current.click();
    };

    const handleClickSelect = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(docs.map(doc => doc._id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    function handleFilesAdd() {
        let fileList = []
        
        for (let i = 0; i < docs.length; i++) {
            if (isCheck.includes(docs[i]._id)) {
                fileList.push(docs[i]._id)
            }
        }

        let type = "Subtopic"
        if(openFiles.moderator){
            type = "Topic"
        }

        console.log(fileList)

        socketRef.current.emit("CONFERENCE_ADD_DOCUMENTS_TO_TOPIC", {
            docIds: fileList, 
            userId: currentUser._id,
            type, 
            parentId: openFiles._id, 
            conferenceId: editConference._id
        },(callback) => { 
            // console.log(callback)
            if(!callback.error){
                setConferenceAddFiles(false)
                setFileList(callback.response)
            }
            
        })
        
    }

    const handleSelectType = (value) => {
        let result = []
        if (value !== "all") {
            result = allDocs.filter(doc => doc.type.includes(value));
        } else {
            result = allDocs
        }
        if (inputRef.current.value) {
            result = result.filter(doc => doc.name.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } 
        setDocs(result)
    };

    const onFileChange = event => {

        if (event.target.files.length > 0) {
            
            let filesArray = []
            for(let i = 0; i < event.target.files.length; i++){
                let file = event.target.files[i]

                file.newName = file.name
                file.previewURL = URL.createObjectURL(file)

                filesArray.push(file)
                fileRefs.current = [...fileRefs.current, createRef()]
            }
            setFilesToBeUploaded(filesArray)

            
        }

    };

    function addDocument() {

        let filesInfo = []
        for(let i = 0; i < filesToBeUploaded.length; i++){
            filesInfo.push({name: fileRefs.current[i].current.value ? fileRefs.current[i].current.value :  filesToBeUploaded[i].newName, type: filesToBeUploaded[i].type})
        }

        var data = { 
            ownerId: currentUser._id, 
            uploadFiles: filesToBeUploaded,
            filesInfo
        };

        let type = "Subtopic"
        if(openFiles.moderator){
            type = "Topic"
        }

        socketRef.current.emit("CONFERENCE_ADD_NEW_LOCAL_FILES_TO_TOPIC", {
            data, 
            parentId: openFiles._id, 
            type,
            conferenceId: editConference._id
        }, (callback) => {
            // console.log(callback)
            if(!callback.error){
                setConferenceAddFiles(false)
                setFileList(callback.response)
            }
            
        });
    }

    function onTextChanged() {
        let result = []
        if (inputRef.current.value) {
            result = docsRef.current.filter(doc => doc.name.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } else {
            result = docsRef.current
        }
        if (currentChoiceRef.current !== "all") {
            result = result.filter(doc => doc.type.includes(currentChoiceRef.current));
        }
        setDocs(result)
    }

    function handleClickAdd() {
        if (isCheck.length > 0) {
            handleFilesAdd();
        } else {
            if (filesToBeUploaded) {
                addDocument();
            }
        }
        

    }

    function handleClose() {
        setConferenceAddFiles(false)
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files.length > 0) {
            let filesArray = []
            for(let i = 0; i < e.dataTransfer.files.length; i++){
                let file = e.dataTransfer.files[i]

                file.newName = file.name
                file.previewURL = URL.createObjectURL(file)

                filesArray.push(file)
                fileRefs.current = [...fileRefs.current, createRef()]
            }
            setFilesToBeUploaded(filesArray)
        }
    };

    function removeLastDotAndAfter(str) {
        const lastDotIndex = str.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return str;
        }
        return str.slice(0, lastDotIndex);
    }

    const createIcon = (file) => {
        if (file.type.includes("video")) {
            return "file-video"
        } else if (file.type.includes("pdf")) {
            return "file-pdf"
        } else if (file.type.includes("word")) {
            return "file-word"
        } else if (file.type.includes("excel")) {
            return "file-excel"
        } else {
            return "file-lines"
        }
    };

    return (
        <PopupBaseComponent>
            {(isOnFocus || fileOnFocus) && <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.68} width="2140px" height="610px" s="1" />}
            <PopupBodyComponent style={{ width: '4579px', height: '1460px' }} type="large">
                <LeftBodyComponent style={{ width: '1167px' }} image={filesAdd} type="Files" subtitle="Add files to:" title={openFiles.name} description="Upload local files or search and select from cloud DOCS to add to the project. You can include various file types to enhance project resources and collaboration." />
                <ClosePageButtonComponent setClose={() => setConferenceAddFiles(false)} />
                <RightBodyComponent flex={true}>
                    <div className={styles.localFiles} >
                        <label className={styles.localFilesLabel}> Add local Files </label>
                        <input
                            ref={inputFileRef}
                            type="file"
                            multiple
                            accept={allowedTypes}
                            onChange={onFileChange}
                            className={styles.input}
                            style={{ display: 'none' }}
                        />
                        <DarkButtonComponent style={{ position: 'absolute', top: '219.5px', left: '1300px', width: '502px', height: '82px', background: 'none' }} onClick={handleBrosweButtonClick}>
                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas["folder-open"]}/>
                            Browse
                        </DarkButtonComponent>

                        <div className={styles.orLine}></div>
                        <LightButtonComponent style={{ position: 'absolute', bottom: '79.5px', left: '1278px', width: '329px', height: '82px', opacity: fileRefs.current.length > 0 || isCheck.length > 0 ? 1 : 0.2}}  onClick={handleClickAdd}>
                            <FontAwesomeIcon className={styles.iconPlus} icon={byPrefixAndName.fas['plus']} /> Add
                        </LightButtonComponent>
                        <DarkButtonComponent style={{ width: '377px', height: '82px', left: '1685px', bottom: '79.5px', position: 'absolute', background: 'none' }} onClick={handleClose} >
                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['xmark']} /> Cancel
                        </DarkButtonComponent>
                        <div className={styles.dragDropZone} onDragOver={handleDragOver} onDrop={handleDrop}>
                            {filesToBeUploaded.length === 0 && <div className={styles.dragDropLabel}>
                                <FontAwesomeIcon className={styles.bigIcon} icon={byPrefixAndName.fal['file-slash']} /> 
                                You have no Local Files
                            </div>}
                            {filesToBeUploaded.length > 0 && filesToBeUploaded.map((file, index) => <div className={styles.inputName} key={index}>
                                
                                <div className={styles.imgDiv}>
                                    {!file.type.includes("image") && <FontAwesomeIcon className={styles.img} icon={byPrefixAndName.fas[createIcon(file)]} />}
                                    {file.type.includes("image") &&<img src={file.previewURL} alt={file.newName} className={styles.img}/>}
                                </div>
                                <InputTextComponent ref={fileRefs.current[index]} styleType='FILES' width="1140px" fontSize="40px" backgroundColor="#1D1536" keyboardRef={keyboardRef} 
                                value={removeLastDotAndAfter(file?.newName)} placeHolder={language.add_files["File name"]} setIsOnFocus={setFileOnFocus}/>
                                {/* <FontAwesomeIcon className={styles.iconTrash} icon={byPrefixAndName.fal['trash']} onClick={() => handleDeleteLocalFile(index)}/>  */}
                                {/* {!fileOnFocus && <FontAwesomeIcon className={styles.iconEditElement} icon={byPrefixAndName.fal['pen']} /> } */}
                                <FontAwesomeIcon className={styles.iconEditElement} icon={byPrefixAndName.fal[fileOnFocus ? 'close' : 'pen']} 
                                onClick={() => { fileRefs.current[index].current.value = ""}}/> 
                            </div>)}
                        </div>
                        <div className={styles.orLine} style={{ top: '1400.5px' }}></div>
                        <div className={styles.verLine}></div>

                        <label className={styles.docsFilesLabel}>Add DOCS files</label>
                        <div className={styles.buttonsDiv}>
                            <InputTextComponent position='absolute' ref={inputRef} width='830px' fontSize="30" keyboardRef={keyboardRef} value="" styleType='SEARCHPOPUP' 
                            placeHolder={language.add_participants.Search} onTextChanged={onTextChanged} setIsOnFocus={setIsOnFocus}/>
                            <FontAwesomeIcon className={styles.iconSearch} icon={byPrefixAndName.fal['magnifying-glass']} style={{color: isOnFocus ? "#33FFC8" : ""}}/>
                        </div>
                        <div className={styles.selectListDiv}>
                            <CustomDropdownComponent options={optionsTypes} currentChoice={currentChoice} setCurrentChoice={setCurrentChoice} width={573}  style={{marginLeft: 56}}/>
                        </div>
                        {docs?.length === 0 && <>
                            <div className={styles.orLine} style={{ left: 3030, width: 1555 }}/>
                            <div className={styles.dragDropLabel} style={{ left: 3590, top: 730}}>
                                <FontAwesomeIcon className={styles.bigIcon} icon={byPrefixAndName.fal['file-slash']} /> 
                                You have no DOCS Files
                            </div>
                            <div className={styles.orLine} style={{ left: 3030, width: 1555, top: '1400.5px' }}/>
                        </>}
                        {docs?.length > 0 && <>
                            <div className={styles.divCheckBox}>
                                <div className={styles.tableHeader}>
                                    <CheckboxComponent text={'  Name'} checked={isCheckAll} onChange={handleSelectAll} style={{marginLeft: 0}} type={true}/>
                                    <div className={styles.centerElement}>Type</div>
                                </div>

                                <ElementTableComponent isFiles='true' docs={docs} isCheck={isCheck} onChange={handleClickSelect} height={943} style={{ marginLeft: 0, marginTop: 5}} />
                            </div>
                        </>}
                        
                    </div>
                </RightBodyComponent>
                {/* <KeyboardComponent visible ref={keyboardRef} x="550" y="180" width="750" height="450" s="1.8" /> */}
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default ConferenceAddFilesComponent;