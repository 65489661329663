import styles from './DetailsCardComponent.module.css';
import titleSvg from '../../../graphics/linie titlu.svg';
import { useEffect } from 'react';

const DetailsCardComponent = ({ title, height, children, indexSelected, isList = true, isBlank = false }) => {

    // Ensure children is always an array
    const normalizedChildren = children ? (Array.isArray(children) ? children : [children]) : [];

    useEffect(() => {
        console.log(normalizedChildren.length);
    }, [indexSelected]);

    return (
        <div className={styles.component} style={{ height: !isList && !isBlank ? 'auto' : '100%' }}>
            <div className={styles.title}>
                {/* <img className={styles.titleGraphic} alt="icon" src={titleSvg} /> */}
                {title.toUpperCase()}
            </div>

            {/* Add scrollable container if it's a list */}
            <div className={isList ? styles.scrollContainer : ''}>
                {normalizedChildren.length > 0 ? (
                    normalizedChildren.map((child, index) => {
                        if (Array.isArray(child)) {
                            return child.map((kid, i) => (
                                <div
                                    className={styles.card}
                                    key={`${index}-${i}`}
                                    style={{
                                        marginTop: index === 0 && i === 0 && (isList || isBlank) ? 120 : 10,
                                        border: i === indexSelected ? "4px solid #77EDDC" : "0px",
                                        background: i === indexSelected
                                            ? "#2D2748 0% 0% no-repeat padding-box"
                                            : "#2D274880 0% 0% no-repeat padding-box",
                                        height: !isList && isBlank ? '100%' : 'auto'
                                    }}
                                >
                                    {kid}
                                </div>
                            ));
                        }
                        return (
                            <div
                                className={styles.card}
                                key={index}
                                style={{
                                    marginTop: index === 0 && (isList || isBlank) ? 120 : 10,
                                    border: index === indexSelected ? "4px solid #77EDDC" : "0px",
                                    background: index === indexSelected
                                        ? "#2D2748 0% 0% no-repeat padding-box"
                                        : "#2D274880 0% 0% no-repeat padding-box",
                                    height: !isList && isBlank ? 2152 : 'auto'
                                }}
                            >
                                {child}
                            </div>
                        );
                    })
                ) : (
                    <div className={styles.card} style={{ marginTop: 120, height }}>
                        {children || "No content available"}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DetailsCardComponent;
