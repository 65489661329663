import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../contexts/globalContext";
import en from "../langs/en.json";
import ro from "../langs/ro.json";
import { ApiLoginUser } from '../utils/RestServices';
import { ApiGetNextOrActiveConference } from '../utils/RestServices';

import Lottie from 'react-lottie-player';

import styles from './LoginComponent.module.css';

import KeyboardComponent from './keyboard/KeyboardComponent';
import InputTextComponent from './keyboard/InputTextComponent';

import video from '../graphics/big wave fundal loop_6.mp4';

import LightButtonComponent from './commons/LightButtonComponent';
import OutsideGlowComponent from './popup/common/OutsideGlowComponent'

import enImg from '../graphics/en.svg';
import roImg from '../graphics/ro.svg';

import arrowLeft from '../graphics/arrow-left-animation.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import io from "socket.io-client"

const LoginComponent = () => {

    const ref = useRef();
    const inputGuestNameRef = useRef();
    const inputVisitorNameRef = useRef();
    const inputUserNameRef = useRef();
    const inputPasswordRef = useRef();
    const keyboardRef = useRef();
    const timerForStartingConference = useRef();
    const loginUserPassFormRef = useRef();

    const loginSocketRef = useRef();


    const [styleType, setStyleType] = useState('POPUP');
    const [isOnFocusEmail, setIsOnFocusEmail] = useState(false);
    const [isOnFocusPassword, setIsOnFocusPassword] = useState(false);
    const [isOnFocusVisitor, setIsOnFocusVisitor] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const deskDataRef = useRef();

    const { setAlertMessage, deskData, language, setLanguage, setCurrentUser, guestLogin, userLogin, appHeight, appWidth, setCurrentConference, setCurrentParticipant, setWaitingForJoining } = useContext(GlobalContext);

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    function setGuestData() {
        if (guestLogin) {
            var body = JSON.stringify(guestLogin);
            ApiLoginUser(body, data => {
                if (!data.error) {
                    CloseTimeoutForStartingConference();
                    setCurrentUser(data.response);
                } else {
                    setAlertMessage({ type: "ERROR", message: "Login error: user data or password wrong" });
                }
            });
        }
    }

    function setUserData() {
        if (inputVisitorNameRef.current.value) {
            // If visitor name is filled, log in as visitor
            var body = JSON.stringify({
                name: inputVisitorNameRef.current.value
            });

            ApiLoginUser(body, data => {
                if (!data.error) {
                    CloseTimeoutForStartingConference();
                    setCurrentUser(data.response);
                } else {
                    setAlertMessage({ type: "ERROR", message: "Login error: user data or password wrong" });
                }
            });
        } else {
            // If email/password is filled, log in as user
            var body = JSON.stringify({
                email: inputUserNameRef.current.value,
                password: inputPasswordRef.current.value
            });

            ApiLoginUser(body, data => {
                if (!data.error) {
                    CloseTimeoutForStartingConference();
                    setCurrentUser(data.response);
                } else {
                    setAlertMessage({ type: "ERROR", message: "Login error: user data or password wrong" });
                }
            });
        }
    }

    const CloseTimeoutForStartingConference = () => {
        if (timerForStartingConference.current) {
            clearTimeout(timerForStartingConference.current);
            timerForStartingConference.current = null;
        }
    }

    useEffect(() => {
        if (deskData) {
            var body = JSON.stringify({
                deskId: deskData._id
            });

            ApiGetNextOrActiveConference(body, data => {
                if (data.conference) {
                    CloseTimeoutForStartingConference();
                    timerForStartingConference.current = setTimeout(() => {
                        setCurrentParticipant(data.participant);
                        setCurrentConference(data.conference);
                    }, data.conference.millisUntilStart);
                }
            });

            loginSocketRef.current = io.connect(process.env.REACT_APP_CMS_URL, {
                secure: true,
                reconnect: true,
                rejectUnauthorized: true,
                query: { token: "login_token_u89wdwdjjjkdf8923eu" }
            })
    
            loginSocketRef.current.on('connect', function () {
                loginSocketRef.current.emit("DESK_JOIN_ROOM", deskData.room);
            });

            loginSocketRef.current.on('PRESENTATION_STARTED', function () {
                var body = JSON.stringify({
                    deskId: deskData._id
                });

                ApiGetNextOrActiveConference(body, data => {
                    if (data.conference) {
                        setWaitingForJoining(true);
                        setCurrentParticipant(data.participant);
                        setCurrentConference(data.conference);
                    }
                });
            });
        }
        deskDataRef.current = deskData;

    }, [deskData])

    useEffect(() => {
        return () => {
            CloseTimeoutForStartingConference();
            loginSocketRef.current?.disconnect()
            loginSocketRef.current = null
        }
    }, [])



    const VisitorOnChangeHandler = () => {
        if (inputVisitorNameRef.current.value) {
            inputUserNameRef.current.disabled = true;
            inputPasswordRef.current.disabled = true;
        } else {
            inputUserNameRef.current.disabled = false;
            inputPasswordRef.current.disabled = false;
        }
    }

    const UserOrEmailOnChangeHandler = () => {
        const emailValue = inputUserNameRef.current?.value || "";
        const passwordValue = inputPasswordRef.current?.value || "";
    
        if (emailValue || passwordValue) {
            inputVisitorNameRef.current.disabled = true;
        } else {
            inputVisitorNameRef.current.disabled = false;
        }
    
        if (emailValue.trim() === "") {
            inputPasswordRef.current.value = ""; // Clear password field
        }
    };
    return (
        <div ref={ref} className={styles.component} style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>

            <video id="background-video" autoPlay loop playsInline muted style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>
                <source src={video} type="video/mp4" />
            </video>
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 2 / 3} width="2140px" height="610px" s="1" position="relative" />
            <div className={styles.inputFields}>
                <div className={styles.languageChoices}>
                    <img src={enImg} className={styles.languageButton} onClick={() => setLanguage(en)} style={{ border: language == en ? "4px solid #FFFFFF" : "" }} />
                    <img src={roImg} className={styles.languageButton} onClick={() => setLanguage(ro)} style={{ border: language == ro ? "4px solid #FFFFFF" : "" }} />
                </div>
                <div >
                    <div className={styles.headerText}>Welcome!</div>
                    <div className={styles.headerDetails}>{language.login_page.Please}</div>
                </div>

                <div className={styles.loginChoices}>
                    {guestLogin && <div className={styles.guestLogin}>
                        <InputTextComponent ref={inputGuestNameRef} keyboardRef={keyboardRef} value={guestLogin.name} fontSize="40px"
                            placeHolder="Name" styleType={styleType} width="1500px" height="120px" />
                        <LightButtonComponent onClick={setGuestData} style={{ width: 281, height: 82, marginTop: -50 }}>
                            <Lottie
                                loop={true}
                                play={true}
                                animationData={arrowLeft}
                                style={{ position: "absolute", width: 60, height: 35, left: 50 }}
                            />
                            <div className={styles.loginPos}> Login</div>
                        </LightButtonComponent>
                    </div>}
                    {!guestLogin && <div className={styles.guestLogin}>
                        <div className={styles.visitorDiv}>
                            <div className={styles.lineVisitor}></div>
                            <label className={styles.textChoice} style={{ width: 674 }}> Login with an email and password </label>
                        </div>
                        <div ref={loginUserPassFormRef}>
                            {isOnFocusEmail && <OutsideGlowComponent style={{ top: 129, left: 185, width: 1510, height: 90, borderRadius: 37 }} />}
                            <InputTextComponent ref={inputUserNameRef} keyboardRef={keyboardRef} value={userLogin ? userLogin.email : ""} fontSize="40px"
                                placeHolder="Email" styleType='POPUP' width="1460px" height="120px" onChange={() => UserOrEmailOnChangeHandler()} setIsOnFocus={setIsOnFocusEmail} />
                            <FontAwesomeIcon className={styles.closeX} onClick={() => {inputUserNameRef.current.value = ""; inputPasswordRef.current.value = ""}} icon={(inputUserNameRef.current?.value === "" && !isOnFocusEmail) ? byPrefixAndName.fas['pen'] : byPrefixAndName.fas['close']} />
                            {isOnFocusPassword && <OutsideGlowComponent style={{ top: 249, left: 185, width: 1510, height: 90, borderRadius: 37 }} />}
                            <InputTextComponent ref={inputPasswordRef} type={showPassword ? "text" : "password"} keyboardRef={keyboardRef} value="" placeHolder={language.login_page.Password} fontSize="40px"
                                styleType='POPUP' width="1460px" height="120px" onChange={() => UserOrEmailOnChangeHandler()} setIsOnFocus={setIsOnFocusPassword} />
                            <FontAwesomeIcon className={styles.closeX} style={{ top: 290, right: 233 }} onClick={togglePasswordVisibility}
                                icon={byPrefixAndName.fas[showPassword ? 'eye-slash' : 'eye']} />
                        </div>

                        <div className={styles.visitorDiv}>
                            <div className={styles.lineVisitor}></div>
                            <label className={styles.textChoice}> Login as visitor </label>
                        </div>


                        {isOnFocusVisitor && <OutsideGlowComponent style={{ top: 449, left: 185, width: 1510, height: 90, borderRadius: 37 }} />}
                        <InputTextComponent ref={inputVisitorNameRef} keyboardRef={keyboardRef} value="" placeHolder={language.login_page.Name}
                            fontSize="40px" styleType='POPUP' width="1460px" height="120px" onChange={() => VisitorOnChangeHandler()} setIsOnFocus={setIsOnFocusVisitor} />

                        <div className={styles.lineFinal} />

                        <LightButtonComponent onClick={setUserData} style={{ width: 281, height: 82, bottom: 80, position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)' }}>
                            <Lottie
                                loop={true}
                                play={true}
                                animationData={arrowLeft}
                                style={{ position: "absolute", width: 60, height: 35, left: 50 }}
                            />
                            <div className={styles.loginPos}> Login</div>
                        </LightButtonComponent>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;
